import { Button, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent, TextField, Box } from '@mui/material';
import React from 'react';
import CustomPopup from "./../components/CustomPopup";
import { DataGrid, GridActionsCellItem, GridRowId, GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import renameKey from '../functions/renameKey';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Api from '../api/api';
import { handleErrorResponse } from '../api/apiErrorhandle';

const myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');
myHeaders.append('Accept', '*/*');

const Contracts: React.FunctionComponent = () => {
    document.title = "Contracts" + " - " + sessionStorage.getItem("PlodName");

    var plod_id = sessionStorage.getItem("plod_id");
    if (plod_id == "" || plod_id == null) { window.location.replace(process.env.REACT_APP_INVALID_TOKEN_REDIRECT_URL || "https://redochre.com/") };


    //#region Mining Company Selector.
    const [miningCompanySelectorID, setMiningCompanySelectorID] = React.useState('0');
    const [miningCompanyData, setMiningCompanyData] = React.useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany`)
                .then(({ data }) => {
                    if (data.length == 0) {

                        setMiningCompanySelectorID("0");
                    }

                    setMiningCompanyData(data);
                }).catch((e) => {
                    handleErrorResponse(e)

                })
        };
        fetchData();
    }, []);

    const handleMiningCompanyChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setMiningCompanySelectorID(event.target.value);
    };
    //#endregion


    function CustomToolbar() {
        const handleAddContractClick = () => {
            setEditContractMode(false);
            setContractPopupVisibility(true);
        };
        return (
            <>
                <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>

                    <div>
                        <FormControl variant="standard" style={{ marginLeft: 5 }}>
                            <Select id="MiningCompanySelector" value={miningCompanySelectorID} onChange={handleMiningCompanyChange} style={{ width: 200, fontSize: 15 }}>
                                <MenuItem value={"0"}>Select a mining company</MenuItem>
                                {miningCompanyData.map(
                                    (data: any): JSX.Element => {
                                        return (
                                            <MenuItem value={data["ID_EA_MiningCompany"]} key={data["ID_EA_MiningCompany"]}>{data["MiningCompanyName"]}</MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </div>

                    <Button color="primary" startIcon={<AddIcon />} onClick={handleAddContractClick} disabled={miningCompanySelectorID == "0"}>
                        Add contract
                    </Button>
                </GridToolbarContainer>
                <hr />
            </>
        )

    }

    //#region Contracts Data
    const [contractRows, setContractRows] = React.useState<any>([])

    const fetchContractData = async () => {
        await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract`)
            .then(({ data }) => {
                if (data.length != 0) {
                    data?.forEach((obj: any) => renameKey(obj, 'ID', 'id'))
                    setContractRows(data)
                }
                else {
                    setContractRows([])
                }
            }).catch((e) => {
                handleErrorResponse(e)

            })
    }

    React.useEffect(() => {
        if (miningCompanySelectorID != "0") {
            fetchContractData();
        }
        else {
            setContractRows([]);
        }
    }, [miningCompanySelectorID])


    const handleEditClick = (id: GridRowId) => () => {
        //Fetches the contract out of the list of rows.
        const selectedContract = contractRows.filter((row: any) => row.id == id)[0];
        setContractName(selectedContract.ContractName);
        setStartDate(dayjs(selectedContract.StartDate));
        setEndDate(dayjs(selectedContract.EndDate));
        setReviewDate(dayjs(selectedContract.ReviewDate));
        setInvoiceCurrency(selectedContract.InvoiceCurrency);
        setTaxNumber(selectedContract.TaxNumber);
        setVRNNumber(selectedContract.VRNNumber);
        setClientInvoicingAddress1(selectedContract.ClientInvoicingAddress1);
        setClientInvoicingAddress2(selectedContract.ClientInvoicingAddress2);
        setClientInvoicingAddress3(selectedContract.ClientInvoicingAddress3);
        setClientInvoicingAddress4(selectedContract.ClientInvoicingAddress4);
        setClientInvoicingAddress5(selectedContract.ClientInvoicingAddress5);
        setClientAttentionTo(selectedContract.ClientAttentionTo);
        setVATRate(selectedContract.VATRate);
        setInvoiceVATText(selectedContract.InvoiceVATText);
        setWithholdingRate(selectedContract.WithholdingRate);
        setBankName(selectedContract.BankName);
        setBankAccountName(selectedContract.BankAccountName);
        setBankAccountNumber(selectedContract.BankAccountNumber);
        setSwiftCode(selectedContract.SwiftCode);
        setEditContractMode(true);
        //Makes the popup show after showing all info.
        setContractPopupVisibility(true);
    };
    const handleDeleteClick = (id: GridRowId) => () => {
        Swal.fire({
            title: 'Do you want to Delete this row?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result: any) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const raw = JSON.stringify({ "ID": id });

                Api.post(`/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/~DeleteItem`, raw)
                    .then(({ data }) => {
                        if (data.Result == 'Deleted') {
                            setContractRows(contractRows?.filter((row: any) => row.id !== id));
                        }
                        else {
                            Swal.fire('Error deleting, make sure is not in use!', '', 'error');
                        };
                    }).catch((e) => {
                        handleErrorResponse(e)

                    })
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
            };
        });
    };

    //#endregion


    //#region Contract Edit/Add Popup
    const [contractPopupVisibility, setContractPopupVisibility] = React.useState(false);
    const [editContractMode, setEditContractMode] = React.useState(false);

    //#region Contract Values
    const [contractName, setContractName] = React.useState<string>("");
    const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
    const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
    const [reviewDate, setReviewDate] = React.useState<Dayjs | null>(null);
    const [invoiceCurrency, setInvoiceCurrency] = React.useState<string>("");
    const [taxNumber, setTaxNumber] = React.useState<string>("");
    const [VRNNumber, setVRNNumber] = React.useState<string>("");
    const [clientInvoicingAddress1, setClientInvoicingAddress1] = React.useState<string>("");
    const [clientInvoicingAddress2, setClientInvoicingAddress2] = React.useState<string>("");
    const [clientInvoicingAddress3, setClientInvoicingAddress3] = React.useState<string>("");
    const [clientInvoicingAddress4, setClientInvoicingAddress4] = React.useState<string>("");
    const [clientInvoicingAddress5, setClientInvoicingAddress5] = React.useState<string>("");
    const [clientAttentionTo, setClientAttentionTo] = React.useState<string>("");
    const [VATRate, setVATRate] = React.useState<number>(0);
    const [invoiceVATText, setInvoiceVATText] = React.useState<string>("");
    const [withholdingRate, setWithholdingRate] = React.useState<number>(0);
    const [bankName, setBankName] = React.useState<string>("");
    const [bankAccountName, setBankAccountName] = React.useState<string>("");
    const [bankAccountNumber, setBankAccountNumber] = React.useState<string>("");
    const [swiftCode, setSwiftCode] = React.useState<string>("");
    //#endregion

    //Clears all the data from the popup
    function setAllContractPopupValuesDefault() {
        setContractName("");
        setStartDate(null);
        setEndDate(null);
        setReviewDate(null);
        setInvoiceCurrency("");
        setTaxNumber("");
        setVRNNumber("");
        setClientInvoicingAddress1("");
        setClientInvoicingAddress2("");
        setClientInvoicingAddress3("");
        setClientInvoicingAddress4("");
        setClientInvoicingAddress5("");
        setClientAttentionTo("");
        setVATRate(0);
        setInvoiceVATText("");
        setWithholdingRate(0);
        setBankName("");
        setBankAccountName("");
        setBankAccountNumber("");
        setSwiftCode("");
    }
    //Handles closing of popup
    function contractPopupCloseHandler(): void {
        setContractPopupVisibility(false);
        setAllContractPopupValuesDefault();
    }

    const submitContract = async () => {
        setContractPopupVisibility(false);
        if (contractName.length == 0 || !contractName.replace(/\s/g, '').length) {
            swal({
                title: "Error",
                text: "The contract name cannot be empty!",
                icon: "error",
                dangerMode: true,
            });

        }

        else {
            var raw = JSON.stringify({
                "ContractName": contractName,
                "StartDate": startDate,
                "EndDate": endDate,
                "ReviewDate": reviewDate,
                "InvoiceCurrency": invoiceCurrency,
                "TaxNumber": taxNumber,
                "VRNNumber": VRNNumber,
                "ClientInvoicingAddress1": clientInvoicingAddress1,
                "ClientInvoicingAddress2": clientInvoicingAddress2,
                "ClientInvoicingAddress3": clientInvoicingAddress3,
                "ClientInvoicingAddress4": clientInvoicingAddress4,
                "ClientInvoicingAddress5": clientInvoicingAddress5,
                "ClientAttentionTo": clientAttentionTo,
                "VATRate": VATRate,
                "InvoiceVATText": invoiceVATText,
                "WithholdingRate": withholdingRate,
                "BankName": bankName,
                "BankAccountName": bankAccountName,
                "BankAccountNumber": bankAccountNumber,
                "SwiftCode": swiftCode

            });


            if (editContractMode) {
                await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/~EditItem`, raw)
                    .catch((e) => swal({
                        title: "Error",
                        text: `An error occured ${e}`,
                        icon: "error",
                        dangerMode: true,
                    })).then(() => fetchContractData());
            }
            else {
                await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/~AddItem`, raw)
                    .catch((e) => swal({
                        title: "Error",
                        text: `An error occured ${e}`,
                        icon: "error",
                        dangerMode: true,
                    })).then(() => fetchContractData());
            }
        }
        setAllContractPopupValuesDefault();
    }

    // ✅ Valid
    const ContractConfigButton = (row: any) => {
        if (row.id) {
            var contractRowsFiltered: any = contractRows.find((row2: any) => row2.id == row.id);
            return (
                <Button href={`contract-setup?MiningCompanyID=${contractRowsFiltered.ID_EA_MiningCompany}&ContractID=${contractRowsFiltered.ID_EC_Contract}`}>
                    Contract Setup
                </Button>
            );
        }
        else {
            return (
                <Button disabled>
                    Error
                </Button>
            );
        };
    };

    //#endregion

    return (
        <>

            <Box sx={{ height: "90vh", margin: "10px", border: 1, borderColor: '#DEDEDE', borderRadius: 2 }}>
                <DataGrid sx={{ zIndex: 0 }}
                    columns={[
                        {
                            field: 'ContractName',
                            headerName: "Contract Name",
                            flex: 1
                        },

                        {
                            field: 'contractSetup',
                            type: 'actions',
                            headerName: 'Contract Setup',
                            width: 150,
                            cellClassName: 'Contract Setup',
                            getActions: ({ id }) => {
                                return [
                                    <ContractConfigButton id={id} />
                                ];
                            }
                        },

                        {
                            field: 'actions',
                            type: 'actions',
                            headerName: 'Actions',
                            width: 100,
                            cellClassName: 'actions',
                            getActions: ({ id }) => {
                                return [
                                    <GridActionsCellItem
                                        icon={<EditIcon />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={handleEditClick(id)}
                                        color="inherit"
                                    />,
                                    <GridActionsCellItem
                                        icon={<DeleteIcon />}
                                        label="Delete"
                                        onClick={handleDeleteClick(id)}
                                        color="inherit"
                                    />,
                                ];
                            }
                        },

                    ]}
                    rows={contractRows}
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </Box>


            <CustomPopup
                onClose={contractPopupCloseHandler}
                show={contractPopupVisibility}
                title={editContractMode ? "Edit Contract" : "Add Contract"}
            >
                <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div >
                            <div style={{ padding: 8 }}>
                                <FormHelperText>Contract Name:</FormHelperText>
                                <TextField style={{ width: 500 }} size="small" required onChange={(e) => setContractName(e.target.value)} value={contractName} />
                            </div>
                            <div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Client Attention To:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setClientAttentionTo(e.target.value)} value={clientAttentionTo} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Client Invoicing Address 1:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setClientInvoicingAddress1(e.target.value)} value={clientInvoicingAddress1} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Client Invoicing Address 2:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setClientInvoicingAddress2(e.target.value)} value={clientInvoicingAddress2} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Client Invoicing Address 3:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setClientInvoicingAddress3(e.target.value)} value={clientInvoicingAddress3} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Client Invoicing Address 4:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setClientInvoicingAddress4(e.target.value)} value={clientInvoicingAddress4} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Client Invoicing Address 5:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setClientInvoicingAddress5(e.target.value)} value={clientInvoicingAddress5} />
                                </div>
                            </div>
                            <div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Start Date:</FormHelperText>
                                    <DatePicker sx={{ width: 500 }} slotProps={{ textField: { size: 'small' } }} onChange={(e) => setStartDate(e)} value={startDate} format="DD/MM/YYYY" />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>End Date:</FormHelperText>
                                    <DatePicker sx={{ width: 500 }} slotProps={{ textField: { size: 'small' } }} onChange={(e) => setEndDate(e)} value={endDate} format="DD/MM/YYYY" />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Review Date:</FormHelperText>
                                    <DatePicker sx={{ width: 500 }} slotProps={{ textField: { size: 'small' } }} onChange={(e) => setReviewDate(e)} value={reviewDate} format="DD/MM/YYYY" />
                                </div>
                            </div>
                            <div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Bank Name:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setBankName(e.target.value)} value={bankName} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Bank Account Name:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setBankAccountName(e.target.value)} value={bankAccountName} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Bank Account Number:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setBankAccountNumber(e.target.value)} value={bankAccountNumber} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Swift Code:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setSwiftCode(e.target.value)} value={swiftCode} />
                                </div>
                            </div>
                            <div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Invoice Currency:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setInvoiceCurrency(e.target.value)} value={invoiceCurrency} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Tax Number:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setTaxNumber(e.target.value)} value={taxNumber} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Vat Rate:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setVATRate(Number(e.target.value))} value={VATRate} type="number" />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Vat Rate Text:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setInvoiceVATText(e.target.value)} value={invoiceVATText} />
                                </div>
                                <div style={{ padding: 8 }}>
                                    <FormHelperText>Withholding Rate:</FormHelperText>
                                    <TextField style={{ width: 500 }} size="small" onChange={(e) => setWithholdingRate(Number(e.target.value))} value={withholdingRate} type="number" />
                                </div>

                            </div>
                            <div>
                                <div style={{ padding: 8 }}>
                                    <Button variant="outlined" style={{ marginTop: 0, width: 500 }} onClick={() => contractPopupCloseHandler()}>Cancel</Button>
                                </div>

                                <div style={{ padding: 8 }}>
                                    <Button variant="outlined" style={{ marginTop: 0, width: 500 }} onClick={() => submitContract()}>Submit</Button>
                                </div>

                            </div>
                        </div>





                    </LocalizationProvider>
                </FormControl >
            </CustomPopup >
        </>
    )
};

export default Contracts;
import React from 'react';
import './index.css';
import { GlobalStyle }  from './styles/global';
import App from './App';
import { createRoot } from 'react-dom/client';
import { store } from './app/store'
import { Provider } from 'react-redux'

const root = createRoot(document.getElementById("root")!);

root.render( <React.StrictMode>
    <Provider store={store}>
  <GlobalStyle />
  <App />
  </Provider>
</React.StrictMode>,
);
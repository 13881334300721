import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import React from "react";
import './../styles/loader.css';

const LoadingSpinner = (props: { show: boolean }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <div
            style={{
                visibility: show ? "visible" : "hidden",
                opacity: show ? "1" : "0"
            }}
            className="overlay"
        ><div >
                <div className="loader" style={{ position: 'absolute', top: "calc(50% - 80px)", left: "calc(50% + 50px)"}}>
                <span className="spinner"/>
                </div>
            </div>
        </div>
    );
};

LoadingSpinner.propTypes = {
    show: PropTypes.bool.isRequired,
};

export default LoadingSpinner;

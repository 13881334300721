import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { useState } from "react";
import { SidebarData } from "./SidebarData";
import styled from "styled-components";
import Api from "../api/api";
import { useSelector } from "react-redux";
import type { RootState } from "../app/store";

const Navbar = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 3.5rem;
  background-color: #000080;
`;
const MenuIconOpen = styled(Link)`
  display: flex;
  justify-content: start;
  font-size: 1.5rem;
  margin-left: 2rem;
  color: #ffffff;
`;
const MenuIconClose = styled(Link)`
  display: flex;
  justify-content: end;
  font-size: 1.5rem;
  margin-top: 0.75rem;
  margin-right: 1rem;
  color: #ffffff;
`;

const SidebarMenu = styled.div`
  width: 250px;
  height: 100vh;
  background-color: #000080;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.6s;
`;
const MenuItems = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 60px;
  padding: 1rem 0 1.25rem;
  user-select: none;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-user-select: none; /* Safari */
`;
const MenuItemLinks = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 20px;
  text-decoration: none;
  color: #ffffff;

  &:hover {
    background-color: #ffffff;
    color: #000080;
    width: 100%;
    height: 45px;
    text-align: center;
    border-radius: 5px;
  }
`;

const Sidebar: React.FunctionComponent = () => {
  var plod_id = sessionStorage.getItem("plod_id");

  const plodId = useSelector((state: RootState) => state.plodData.plod_id);
  const plod = plodId || plod_id;

  const PlodName = sessionStorage.getItem("PlodName");

  const [access, setAccess] = useState([]);
  const getAccess = async () => {
    try {
      const { data, status } = await Api.post(
        `api/Plods/ID_${plod}/LoggedInTeamMember/0/~MenuItemsAvailable`
      );

      if (status == 200) {
        const loginMember = data;
        setAccess(loginMember);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (plod_id) {
      getAccess();
    }
  }, [plod_id]);

  const hasRoles = access?.length;

  const hasAccess = (path: string) => {
    if (!hasRoles) {
      return false;
    }
    const isAccess = access.find(
      (i: any) =>
        i.Menu.toLowerCase() == path.toLowerCase() &&
        (i.Active == "True" || i.Active == true)
    );
    if (isAccess) {
      return true;
    }
    return false;
  };

  return (
    <>
      <SidebarMenu style={{
        userSelect: 'none',
        WebkitUserSelect: 'none', /* Safari */
        MozUserSelect: 'none', /* Firefox */
        msUserSelect: 'none' /* Internet Explorer/Edge */
      }}>
        {SidebarData.map((item, index) => {
          if (item.title === "Name") {
            return (
              <div key={index}>
                <h3
                  style={{
                    color: "white",
                    paddingTop: 10,
                    marginLeft: 25,
                    userSelect: 'none',
                    WebkitUserSelect: 'none', /* Safari */
                    MozUserSelect: 'none', /* Firefox */
                    msUserSelect: 'none' /* Internet Explorer/Edge */
                  }}
                >
                  {PlodName}
                </h3>

                <h1
                  style={{
                    color: "white",
                    paddingTop: 10,
                    textAlign: "center",
                    userSelect: 'none',
                    WebkitUserSelect: 'none', /* Safari */
                    MozUserSelect: 'none', /* Firefox */
                    msUserSelect: 'none' /* Internet Explorer/Edge */
                  }}
                >
                  DRILL DOWN
                  <br />
                  <span
                    style={{
                      fontWeight: "bold",
                      left: 0,
                      position: "relative",
                      top: -15,
                      fontSize: 14,
                    }}
                  ></span>
                </h1>
                <br />
              </div>
            );
          }
          if (item.title === "Spacer") {
            return (
              <>
                {hasRoles ? (
                  <hr
                    style={{ marginLeft: "16px", marginRight: "16px" }}
                    key={index}
                  ></hr>
                ) : null}
              </>
            );
          }
          return (
            <>
              {!hasRoles && item.path == "/" ? (
                <MenuItems key={index}>
                  <MenuItemLinks to={item.path}>
                    {item.icon}
                    <span style={{ marginLeft: "16px" }}>{item.title}</span>
                  </MenuItemLinks>
                </MenuItems>
              ) : hasAccess(item.title) ? (
                <>
                  <MenuItems key={index}>
                    <MenuItemLinks to={item.path}>
                      {item.icon}
                      <span style={{ marginLeft: "16px" }}>{item.title == 'Teams'? "Team" :item.title}</span>
                    </MenuItemLinks>
                  </MenuItems>
                </>
              ) : null}
            </>
          );
        })}
      </SidebarMenu>
    </>
  );
};

export default Sidebar;

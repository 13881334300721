import Swal from "sweetalert2";

export const handleErrorResponse = (error: any) => {
  console.log(error);
  const errorMessage =
    error?.response?.data?.["Exception.RedOchre.Cloud"] ||
    "Something went wrong.";

  Swal.fire({
    icon: "error",
    title: errorMessage,
  });
};

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NotFound from "./pages/NotFound";

import AppLayout from "./layout/AppLayout";

import Home from "./pages/Home";
import Lists from "./pages/Lists";
import Rigs from "./pages/Rigs";
import Bits from "./pages/Bits";

import Clients from "./pages/Clients";
import Contracts from "./pages/Contracts";
import ContractsSetup from "./pages/Contracts-Setup";

import NewPlod from "./pages/New-Plod";
import ReviewAndSignoff from "./pages/Review-And-Signoff";

import Reports from "./pages/Reports";

import Invoicing from "./pages/Invoicing";
import Teams from "./pages/Teams";

const App: React.FunctionComponent = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="*" Component={NotFound} />

          <Route path="/" element={<AppLayout />}>
            <Route index element={<Home />} />
            <Route path="/lists" element={<Lists />} />
            <Route path="/rigs" element={<Rigs />} />
            <Route path="/bits" element={<Bits />} />

            <Route path="/clients" element={<Clients />} />
            <Route path="/contracts" element={<Contracts />} />
            <Route path="/contract-setup" element={<ContractsSetup />} />

            <Route path="/new-plod" element={<NewPlod />} />
            <Route path="/review-and-signoff" element={<ReviewAndSignoff />} />

            <Route path="/reports" element={<Reports />} />

            <Route path="/invoicing" element={<Invoicing />} />
            <Route path="/teams" element={<Teams />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;

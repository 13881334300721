function checkNewPlodData(operatorsRows:any,drillingDetailsRows:any,tasksRows:any,consumablesRows:any) {
    if (operatorsRows.length == 0) {
        return "OPERATORS ROWS CAN'T BE EMPTY!";
    };
    
    if (drillingDetailsRows.length == 0) {
        return "DRILLING DETAILS ROW CAN'T BE EMPTY!";
    };

    if((drillingDetailsRows.reduce((total: any, obj: any) => (obj.hours) + total, 0) + tasksRows.reduce((total: any, obj: any) => (obj.hours) + total, 0))!= 12){
        return "TOTAL HOURS MUST ADD UP TO 12!";
    };

    return true;
};
  
export default checkNewPlodData;
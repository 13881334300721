import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface plodData {
  plod_id: number,
  plodName: string,
}
const initialState: plodData = {
  plod_id: 0,
  plodName: ""
};

export const plodData = createSlice({
  name: 'plodData',
  initialState,
  reducers: {
    stateData: (state, action) => {
      console.log(action)
      state.plod_id = action.payload.ID;
      state.plodName = action.payload.Plod_App_DrillingCompanyName;
    }
  },
})

// Action creators are generated for each case reducer function
export const { stateData } = plodData.actions

export default plodData.reducer
//#region Imports!
import React from "react";

//The images.
import ShiftInformation from "./../assets/ShiftInformation.gif";

import {
  Button,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextareaAutosize,
} from "@mui/material";

//Tools.
import renameKey from "../functions/renameKey";
import { FilterSpacing } from "../components/FilterSpacing";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import isWhiteSpaceOrEmpty from "./../functions/isWhiteSpaceOrEmpty";
import checkNewPlodData from "./../functions/checkNewPlodData";

//Types.
import { MiningCompany } from "./../types/miningCompanyType";
import { Contract } from "./../types/contractType";
import { DrillingProgram } from "./../types/drillingProgramType";
import { Rig } from "./../types/rigType";
import { Site } from "./../types/siteType";

//Components.
import NewPlodBox from "../components/newPlodBox";
import Swal from "sweetalert2";

import PlodDetails from "./../components/PlodDetailsComponent";
import Api from "../api/api";
import { handleErrorResponse } from "../api/apiErrorhandle";
//#endregion

const NewPlod: React.FunctionComponent = () => {
  document.title = "New Plod" + " - " + sessionStorage.getItem("PlodName");
  var plod_id = sessionStorage.getItem("plod_id");
  if (plod_id == "" || plod_id == null) {
    window.location.replace(
      "https://login.redochre.cloud/"
    );
  }

  //#region Filters.

  const [dateValue, setDateValue] = React.useState<Dayjs | null>(dayjs());

  const [shiftFilter, setShiftFilter] = React.useState<string>("SelectShift");
  const handleShiftFilterChange = (event: SelectChangeEvent) => {
    setShiftFilter(event.target.value as string);
  };

  const [statusFilter, setStatusFilter] =
    React.useState<string>("SelectStatus");
  const handleStatusFilterChange = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value as string);
  };

  const [miningCompanyFilter, setMiningCompanyFilter] = React.useState<string>(
    "SelectMiningCompany"
  );
  const handleMiningCompanyFilterChange = (event: SelectChangeEvent) => {
    setMiningCompanyFilter(event.target.value as string);
  };

  const [contractFilter, setContractFilter] =
    React.useState<string>("SelectContract");
  const handleContractFilterChange = (event: SelectChangeEvent) => {
    setContractFilter(event.target.value as string);
  };

  const [drillingProgramFilter, setDrillingProgramFilter] =
    React.useState<string>("SelectDrillingProgram");
  const handleDrillingProgramFilterChange = (event: SelectChangeEvent) => {
    setDrillingProgramFilter(event.target.value as string);
  };

  const [siteFilter, setSiteFilter] = React.useState<string>("SelectSite");
  const handleSiteFilterChange = (event: SelectChangeEvent) => {
    setSiteFilter(event.target.value as string);
  };

  const [rigFilter, setRigFilter] = React.useState<string>("SelectRig");
  const handleRigFilterChange = (event: SelectChangeEvent) => {
    setRigFilter(event.target.value as string);
  };

  //#region data populating.

  //#region Fetching data for Mining Company Selector.
  const [miningCompanyData, setMiningCompanyData] = React.useState<
    MiningCompany[]
  >([]);

  React.useEffect(() => {
    const fetchMiningCompanyData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany`)
        .then(({ data }) => {
          setMiningCompanyFilter("SelectMiningCompany");
          setMiningCompanyData(data);
        })
        .catch((e) => {
          console.log(e?.message);
        });
    };
    fetchMiningCompanyData();
  }, []);
  //#endregion

  //#region Fetching data for Contract Selector.
  const [contractData, setContractData] = React.useState<Contract[]>([]);
  React.useEffect(() => {
    const fetchContractData = async () => {
      if (miningCompanyFilter != "SelectMiningCompany") {
        await Api.post(
          `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanyFilter}/Contract`
        )
          .then(({ data }) => {
            if (data.length == 0) {
              setContractFilter("SelectContract");
              setContractData([]);
            } else {
              setContractData(data);
            }
          })
          .catch((e) => {
            console.log(e?.message);
          });
      } else {
        setContractData([]);
        setContractFilter("SelectContract");
        setDrillingProgramFilter("SelectDrillingProgram");
      }
    };
    fetchContractData();
  }, [miningCompanyFilter]);
  //#endregion

  //#region Fetching data for Contract Selector.
  const [drillingProgramData, setDrillingProgramData] = React.useState<
    DrillingProgram[]
  >([]);
  React.useEffect(() => {
    const fetchDrillingProgramData = async () => {
      if (contractFilter != "SelectContract") {
        await Api.post(
          `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanyFilter}/Contract/ID_${contractFilter}/DrillingProgram`
        )
          .then(({ data }) => {
            if (data.length == 0) {
              setDrillingProgramFilter("SelectDrillingProgram");
              setDrillingProgramData([]);
            } else {
              setDrillingProgramFilter(data[0].ID);
              setDrillingProgramData(data);
            }
          })
          .catch((e) => {
            console.log(e?.message);
          });
      } else {
        setDrillingProgramData([]);
        setDrillingProgramFilter("SelectDrillingProgram");
      }
    };
    fetchDrillingProgramData();
  }, [contractFilter]);
  //#endregion

  //#region Fetching data for Site Selector.
  const [siteData, setSiteData] = React.useState<Site[]>([]);
  React.useEffect(() => {
    const fetchSiteData = async () => {
      if (drillingProgramFilter != "SelectDrillingProgram") {
        await Api.post(
          `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanyFilter}/Contract/ID_${contractFilter}/DrillingProgram/ID_${drillingProgramFilter}/~DPSite_GetValues`
        )
          .then(({ data }) => {
            if (data.length == 0) {
              setSiteFilter("SelectSite");
              setSiteData([]);
            } else {
              setSiteData(data);
            }
          })
          .catch((e) => {
            handleErrorResponse(e);
          });
      } else {
        setSiteData([]);
        setSiteFilter("SelectSite");
      }
    };
    fetchSiteData();
  }, [drillingProgramFilter]);
  //#endregion

  //#region Fetching data for Rig Selector.
  const [rigData, setRigData] = React.useState<Rig[]>([]);
  React.useEffect(() => {
    const fetchRigData = async () => {
      if (drillingProgramFilter != "SelectDrillingProgram") {
        await Api.post(
          `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanyFilter}/Contract/ID_${contractFilter}/DrillingProgram/ID_${drillingProgramFilter}/~DPRig_GetValues`
        )
          .then(({ data }) => {
            if (data.length == 0) {
              setRigFilter("SelectRig");
              setRigData([]);
            } else {
              setRigData(data);
            }
          })
          .catch((e) => {
            handleErrorResponse(e);
          });
      } else {
        setRigData([]);
        setRigFilter("SelectRig");
      }
    };
    fetchRigData();
  }, [drillingProgramFilter]);
  //#endregion
  //#endregion

  //#endregion

  return (
    <>
      <NewPlodBox
        color="rgb(145, 149, 154)"
        image={ShiftInformation}
        text="Shift Information"
        height="100%"
      >
        <div style={{ marginLeft: 50, marginRight: 50 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              style={{
                display: "flex",
                margin: 25,
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <FilterSpacing>
                <FormHelperText>Date</FormHelperText>
                <DatePicker
                  value={dateValue}
                  onChange={(newValue) => setDateValue(newValue)}
                  format="DD/MM/YYYY"
                  sx={{ width: 259 }}
                />
              </FilterSpacing>

              <FilterSpacing>
                <FormHelperText>Shift</FormHelperText>
                <Select
                  id="shiftFilter"
                  value={shiftFilter}
                  onChange={handleShiftFilterChange}
                  style={{ width: 259 }}
                >
                  <MenuItem value={"SelectShift"} key={"SelectShift"}>
                    Select Shift...
                  </MenuItem>
                  <MenuItem value={"Day"} key={"Day"}>
                    Day
                  </MenuItem>
                  <MenuItem value={"Night"} key={"Night"}>
                    Night
                  </MenuItem>
                </Select>
              </FilterSpacing>

              <FilterSpacing>
                <FormHelperText>Status</FormHelperText>
                <Select
                  id="statusFilter"
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  style={{ width: 259 }}
                >
                  <MenuItem value={"SelectStatus"} key={"SelectStatus"}>
                    Select Status...
                  </MenuItem>
                  <MenuItem value={"NONE"} key={"NONE"}>
                    NONE
                  </MenuItem>
                  <MenuItem
                    value={"WAITINGFORSUPERVISOR"}
                    key={"WAITINGFORSUPERVISOR"}
                  >
                    WAITING FOR SUPERVISOR
                  </MenuItem>
                  <MenuItem value={"WAITINGFORCLIENT"} key={"WAITINGFORCLIENT"}>
                    WAITING FOR CLIENT
                  </MenuItem>
                  <MenuItem value={"APPROVED"} key={"APPROVED"}>
                    APPROVED
                  </MenuItem>
                </Select>
              </FilterSpacing>

              <FilterSpacing>
                <FormHelperText>Mining Company</FormHelperText>
                <Select
                  id="miningCompanyFilter"
                  value={miningCompanyFilter}
                  onChange={handleMiningCompanyFilterChange}
                  style={{ width: 259 }}
                >
                  <MenuItem value={"SelectMiningCompany"}>
                    Select Mining Company...
                  </MenuItem>
                  {miningCompanyData.map((data: MiningCompany): JSX.Element => {
                    return (
                      <MenuItem
                        value={data.ID_EA_MiningCompany}
                        key={data.ID_EA_MiningCompany}
                      >
                        {data.MiningCompanyName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FilterSpacing>

              <FilterSpacing>
                <FormHelperText>Contract</FormHelperText>
                <Select
                  id="contractFilter"
                  value={contractFilter}
                  onChange={handleContractFilterChange}
                  style={{ width: 259 }}
                  disabled={miningCompanyFilter == "SelectMiningCompany"}
                >
                  <MenuItem value={"SelectContract"}>
                    Select Contract...
                  </MenuItem>
                  {contractData.map((data: Contract): JSX.Element => {
                    return (
                      <MenuItem
                        value={data.ID_EC_Contract}
                        key={data.ID_EC_Contract}
                      >
                        {data.ContractName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FilterSpacing>

              <FilterSpacing>
                <FormHelperText>Drilling Program</FormHelperText>
                <Select
                  id="drillingProgramFilter"
                  value={drillingProgramFilter}
                  onChange={handleDrillingProgramFilterChange}
                  style={{ width: 259 }}
                  disabled={contractFilter == "SelectContract"}
                >
                  <MenuItem value={"SelectDrillingProgram"}>
                    Select Drilling Program...
                  </MenuItem>
                  {drillingProgramData.map(
                    (data: DrillingProgram): JSX.Element => {
                      return (
                        <MenuItem
                          value={data.ID_EDP_Drilling_Program}
                          key={data.ID_EDP_Drilling_Program}
                        >
                          {data.DrillingProgramName}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FilterSpacing>

              <FilterSpacing>
                <FormHelperText>Rig</FormHelperText>
                <Select
                  id="rigFilter"
                  value={rigFilter}
                  onChange={handleRigFilterChange}
                  style={{ width: 259 }}
                  disabled={drillingProgramFilter == "SelectDrillingProgram"}
                >
                  <MenuItem value={"SelectRig"}>Select Rig...</MenuItem>
                  {rigData.map((data: any): JSX.Element => {
                    return (
                      <MenuItem
                        value={data["ID_EA_Rig"]}
                        key={data["ID_EA_Rig"]}
                      >
                        {data["RigName"]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FilterSpacing>

              <FilterSpacing>
                <FormHelperText>Site</FormHelperText>
                <Select
                  id="siteFilter"
                  value={siteFilter}
                  onChange={handleSiteFilterChange}
                  style={{ width: 259 }}
                  disabled={drillingProgramFilter == "SelectDrillingProgram"}
                >
                  <MenuItem value={"SelectSite"}>Select Site...</MenuItem>
                  {siteData.map((data: any): JSX.Element => {
                    return (
                      <MenuItem
                        value={data["ID_EA_Site"]}
                        key={data["ID_EA_Site"]}
                      >
                        {data["SiteName"]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FilterSpacing>
            </div>
          </LocalizationProvider>
        </div>
      </NewPlodBox>

      <PlodDetails
        plod_id={plod_id || ""}
        save={true}
        drillingProgramSelectorID={drillingProgramFilter}
        contractSelectorID={contractFilter}
        miningCompanySelectorID={miningCompanyFilter}
        statusFilter={statusFilter}
        shiftFilter={shiftFilter}
        dateValue={dateValue || dayjs()}
        rigFilter={rigFilter}
        siteFilter={siteFilter}
        shiftID={"0"}
      />
    </>
  );
};

export default NewPlod;

function isWhiteSpaceOrEmpty(data: string) {
    if (!data && data != "") {
        return false;
    }
    else if (/\S/.test(data)) {
        return false;
    }
    else {
        return true;
    }
}

export default isWhiteSpaceOrEmpty;
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
  selectedGridRowsCountSelector,
} from "@mui/x-data-grid";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import renameKey from "../functions/renameKey";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { randomId } from "@mui/x-data-grid-generator";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Api from "../api/api";
import { handleErrorResponse } from "../api/apiErrorhandle";
import { Axios, AxiosError } from "axios";

const ContractsSetup: React.FunctionComponent = () => {
  document.title =
    "Contracts Setup" + " - " + sessionStorage.getItem("PlodName");
  var plod_id = sessionStorage.getItem("plod_id");
  if (plod_id == "" || plod_id == null) {
    window.location.replace("https://login.redochre.cloud/");
  }

  const search: string = useLocation().search;
  const searchParams: URLSearchParams = new URLSearchParams(search);
  const URLMiningCompanyID: string = searchParams.get("MiningCompanyID") || "";
  const URLContractID: string = searchParams.get("ContractID") || "";

  //#region Data Selectors
  //#region Mining Company
  //#region For Mining Company Selector
  const [miningCompanySelectorID, setMiningCompanySelectorID] =
    React.useState("0");
  const [bitSizeId, setBitSizeId] = React.useState("0");
  const [miningCompanyData, setMiningCompanyData] = React.useState<any>([]);
  const [bitSizeData, setBitSizeData] = React.useState<any>([]);
  const handleMiningCompanySelectorChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    setMiningCompanySelectorID(event.target.value);
  };

  const handleBitSizeSelectorChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    setBitSizeId(event.target.value);
  };
  //#endregion

  //#region Fetching data for Mining Company Selector.
  React.useEffect(() => {
    const fetchMiningCompanyData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany`)
        .then(({ data }) => {
          if (data.length == 0) {
            setMiningCompanySelectorID("0");
          } else {
            if (URLMiningCompanyID == "") {
              setMiningCompanySelectorID(data[0].ID_EA_MiningCompany);
            } else {
              setMiningCompanySelectorID(URLMiningCompanyID);
            }
          }
          setMiningCompanyData(data);
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    };
    fetchMiningCompanyData();
  }, []);
  //#endregion
  //#endregion

  // getBitSize Data Api Calling

  //#region Contract
  //#region For Contract Selector
  const [contractSelectorID, setContractSelectorID] = React.useState("0");
  const [contractData, setContractData] = React.useState<any>([]);
  const handleContractSelectorChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    setContractSelectorID(event.target.value);
  };
  //#endregion

  //#region Fetching data for Mining Company Selector.
  React.useEffect(() => {
    const fetchContractData = async () => {
      if (miningCompanySelectorID != "0") {
        Api.post(
          `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract`
        )
          .then(({ data }) => {
            if (data.length == 0) {
              setContractSelectorID("0");
              setDrillingProgramSelectorID("0");
              setDrillingProgramData([]);
            } else {
              if (URLMiningCompanyID == miningCompanySelectorID) {
                setContractSelectorID(URLContractID);
              } else {
                setContractSelectorID(data[0].ID_EC_Contract);
              }
            }
            setContractData(data);
          })
          .catch((e) => {
            handleErrorResponse(e);
          });
      }
    };
    fetchContractData();
  }, [miningCompanySelectorID]);
  //#endregion
  //#endregion

  //#region Contract
  //#region For Contract Selector
  const [drillingProgramSelectorID, setDrillingProgramSelectorID] =
    React.useState("0");
  const [drillingProgramData, setDrillingProgramData] = React.useState<any>([]);
  const handleDrillingProgramSelectorChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    setDrillingProgramSelectorID(event.target.value);
  };
  //#endregion

  //#region Fetching data for Mining Company Selector.
  React.useEffect(() => {
    const fetchDrillingProgramData = async () => {
      if (contractSelectorID != "0") {
        Api.post(
          `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram`
        )
          .then(({ data }) => {
            if (data.length == 0) {
              setDrillingProgramSelectorID("0");
            } else {
              setDrillingProgramSelectorID(data[0].ID_EDP_Drilling_Program);
            }
            setDrillingProgramData(data);
          })
          .catch((e) => {
            handleErrorResponse(e);
          });
      }
    };
    fetchDrillingProgramData();
  }, [contractSelectorID]);
  //#endregion
  //#endregion
  //#endregion

  //#region Tabs.
  //#region Mode Tab
  const [modeTabValue, setModeTabValue] = React.useState("ContractSetup");
  const [optionsTabValue, setOptionsTabValue] = React.useState("DPRig");
  const handleModeTabChange = (event: React.SyntheticEvent, newTab: string) => {
    if (newTab == "ContractSetup") {
      setOptionsTabValue(contractSetupTabValue);
    } else {
      setOptionsTabValue(contractCostTabValue);
    }
    setModeTabValue(newTab);
  };
  //#endregion

  //#region Contract Setup Tab
  const [contractSetupTabValue, setContractSetupTabValue] =
    React.useState("DPRig");

  const handleContractSetupTabChange = (
    event: React.SyntheticEvent,
    newTab: string
  ) => {
    setContractSetupTabValue(newTab);
    setOptionsTabValue(newTab);
  };
  //#endregion

  //#region Contract Cost Tab
  const [contractCostTabValue, setContractCostTabValue] =
    React.useState("TaskChargeType");
  const handleContractCostTabChange = (
    event: React.SyntheticEvent,
    newTab: string
  ) => {
    setContractCostTabValue(newTab);
    setOptionsTabValue(newTab);
  };
  //#endregion

  //#endregion

  React.useEffect(() => {
    const body = `
   DPBitSize(){
       ID
       bit_size{   
           Size
       }
   }
   })`;
    const fetchBitSize = async () => {
      await Api.post(
        `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/0/DPBitSize/~GraphQL`,
        body
      )
        .then(({ data }: any) => {
          if (data?.data?.DPBitSize) {
            if (data?.data?.DPBitSize == 0) {
              setBitSizeId("0");
            } else {
              setBitSizeId(data?.data?.DPBitSize[0].ID);
            }
            // Log the data received from the API
            const bitData = data?.data?.DPBitSize?.map((i: any) => {
              return {
                ID: i.ID,
                bitSize: i?.bit_size[0]?.Size || "",
              };
            });

            setBitSizeData(bitData);
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    };
    if (plod_id && contractSelectorID != "0" && miningCompanySelectorID) {
      fetchBitSize();
    }
  }, [plod_id, contractSelectorID, miningCompanySelectorID]);

  //#region Fetching Data For Filters
  const [rigOptions, setRigOptions] = React.useState([]);
  const [consumableOptions, setConsumableOptions] = React.useState([]);
  const [siteOptions, setSiteOptions] = React.useState([]);
  const [taskOptions, setTaskOptions] = React.useState([]);
  const [personnelOptions, setPersonnelOptions] = React.useState([]);
  const [personnelTypeOptions, setPersonnelTypeOptions] = React.useState([]);
  const [chargeTypeOptions, setChargeTypeOptions] = React.useState([]);
  const [taskTypeOptions, setTaskTypeOptions] = React.useState([]);

  const [bitSizeOptions, setBitSizeOptions] = React.useState([]);
  //#region The API Requests
  const fetchOptionsData = async () => {
    const body = `{
      Plods(ID_AP_Plod: ${plod_id}) {
        Rigs {
          value: ID_EA_Rig
          label: RigName
        }
        Consumables {
          value: ID_EA_Consumable
          label: ConsumableName
        }
        Site {
          value: ID_EA_Site
          label: SiteName
        }
        Tasks {
          value: ID_EA_Task
          label: TaskName
        }
        Personnel {
          value: ID_EA_Personnel
          FirstName
          LastName
        }
        PersonnelType {
          value: ID_EA_PersonnelType
          label: PersonnelTypeName
        }
        BitSizes {
          value: ID_EA_BitSize
          label: Size
        }
      }
    }
    `;

    Api.post(`/api/Plods/~GraphQL`, body)
      .then(({ data, status }) => {
        if (status == 200) {
          setRigOptions(data.data?.Plods?.[0]?.Rigs);
          setConsumableOptions(data?.data?.Plods?.[0]?.Consumables);
          setSiteOptions(data?.data?.Plods?.[0]?.Site);
          setTaskOptions(data?.data?.Plods?.[0]?.Tasks);
          //Combines the first and last name together :).
          data.data?.Plods?.[0]?.Personnel.forEach(
            (obj: any) => (obj.label = obj.FirstName + " " + obj.LastName)
          );
          setPersonnelOptions(data.data?.Plods?.[0]?.Personnel);
          setPersonnelTypeOptions(data?.data?.Plods?.[0]?.PersonnelType);
          setBitSizeOptions(data?.data?.Plods?.[0]?.BitSizes);
        }
      })
      .catch((err) =>
        swal({
          title: "Error",
          text: `Error occured ${err}`,
          icon: "error",
          dangerMode: true,
        })
      );
  };

  //Ensures that it only runs once.
  React.useEffect(() => {
    fetchOptionsData();
  }, []);

  const fetchChargeTypeOptions = async () => {
    if (drillingProgramSelectorID != "0") {
      await Api.post(
        `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}/TaskChargeType`
      )
        .then(({ data }) => {
          data?.forEach((obj: any) =>
            renameKey(obj, "ID_EDP_Task_ChargeType", "value")
          );
          data?.forEach(
            (obj: any) =>
            (obj.label =
              obj.ChargeType +
              " - $" +
              (Math.round(obj.Hourly_Rate * 100) / 100).toFixed(2))
          );
          setChargeTypeOptions(data);
        })
        .catch((err) =>
          swal({
            title: "Error",
            text: `Error occured ${err}`,
            icon: "error",
            dangerMode: true,
          })
        );
    }
  };
  React.useEffect(() => {
    fetchChargeTypeOptions();
  }, [drillingProgramSelectorID, modeTabValue]);

  //type option taskmetric

  const fetchTaskTypeOptions = async () => {
    if (drillingProgramSelectorID != "0") {
      await Api.post(`/api/Plods/ID_${plod_id}/TaskMetric`)
        .then(({ data }) => {
          setTaskTypeOptions(
            data?.map((item: any) => ({
              value: item?.ID_EA_TaskMetric,
              label: item?.TaskMetric,
            }))
          );
        })
        .catch((err) =>
          swal({
            title: "Error",
            text: `Error occured ${err}`,
            icon: "error",
            dangerMode: true,
          })
        );
    }
  };
  React.useEffect(() => {
    fetchTaskTypeOptions();
  }, [drillingProgramSelectorID, modeTabValue]);

  //#endregion

  const [dataRowModesModel, setDataRowModesModel] =
    React.useState<GridRowModesModel>({});
  const [dataRows, setDataRows] = React.useState<any>([]);
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setDataRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params: any,
    event: any
  ) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    let updatedRow: any = {};

    var raw = "";
    if (optionsTabValue == "DPRig") {
      raw = JSON.stringify({
        ID_EA_Rig: newRow.ID_EA_Rig,
      });
    } else if (optionsTabValue == "DPConsumable") {
      raw = JSON.stringify({
        ID_EA_Consumable: newRow.ID_EA_Consumable,
        Charge: newRow.Charge,
        Default_Charge: newRow.Default_Charge,
      });
    } else if (optionsTabValue == "Site") {
      raw = JSON.stringify({
        ID_EA_Site: newRow.ID_EA_Site,
      });
    } else if (optionsTabValue == "DPPersonnel") {
      raw = JSON.stringify({
        ID_EA_Personnel: newRow.ID_EA_Personnel,
        ID_EA_PersonnelType: newRow.ID_EA_PersonnelType,
      });
    } else if (optionsTabValue == "Task") {
      raw = JSON.stringify({
        ID_EA_Task: newRow.ID_EA_Task,
        ID_EDP_Task_ChargeType: newRow.ID_EDP_Task_ChargeType,
        ChargeType_Locked: newRow.ChargeType_Locked,
        Task_Metric: newRow.Task_Metric,
        Fixed_Cost: newRow.Fixed_Cost,
      });
    } else if (optionsTabValue == "TaskChargeType") {
      raw = JSON.stringify({
        Hourly_Rate: newRow.Hourly_Rate,
        ChargeType: newRow.ChargeType,
      });
    } else if (optionsTabValue == "MonthlyCharges") {
      raw = JSON.stringify({
        Monthly_Charge_Name: newRow.Monthly_Charge_Name,
        Monthly_Charge: newRow.Monthly_Charge,
      });
    } else if (optionsTabValue == "ShiftCharges") {
      raw = JSON.stringify({
        Shift_Charge_Name: newRow.Shift_Charge_Name,
        Shift_Charge: newRow.Shift_Charge,
        Day: newRow.Day,
        Night: newRow.Night,
      });
    } else if (optionsTabValue == "PerMRates") {
      raw = JSON.stringify({
        From_Meter: newRow.From_Meter,
        To_Meter: newRow.To_Meter,
        Charge_Per_Meter: newRow.Charge_Per_Meter,
      });
    } else if (optionsTabValue == "DPBitSize") {
      raw = JSON.stringify({
        bit_size: [{ Link_Value: { ID: newRow.ID_EA_BitSize } }],
      });
    } else if (optionsTabValue == "OneOffCharges") {
      raw = JSON.stringify({
        One_Off_Charge_Name: newRow.One_Off_Charge_Name,
        One_Off_Charge: newRow.One_Off_Charge,
      });
    }

    // /api/Plods/ID_1063/MiningCompany/ID_2080/Contract/ID_1079/DrillingProgram/ID_40/DPBitSize/ID_10/DrillingCharges/~AddItem

    let url = `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}/${optionsTabValue}/~AddItem`;
    if (optionsTabValue == "PerMRates") {
      url = `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}/DPBitSize/ID_${bitSizeId}/DrillingCharges/~AddItem`;
    }
    await Api.post(url, raw)
      .then(({ data }) => {
        if (data.ID) {
          updatedRow = { ...newRow, isNew: false, ID: data.ID };
          setDataRows(
            dataRows?.map((row: any) =>
              row.ID === newRow.ID ? updatedRow : row
            )
          );
        } else {
          swal({
            title: "Error",
            text: "Error adding.",
            icon: "error",
            dangerMode: true,
          });
        }
      })
      .catch((e) => {
        handleErrorResponse(e);
      });

    return updatedRow;
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    Swal.fire({
      title: "Do you want to Delete this row?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result: any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const raw = JSON.stringify({ ID: id });
        let url = `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}/`;
        if (optionsTabValue == "PerMRates") {
          url = `${url}/DPBitSize/ID_${bitSizeId}/DrillingCharges/~DeleteItem`;
        } else {
          url = url + `${optionsTabValue}/~DeleteItem`;
        }
        Api.post(url, raw)
          .then(({ data }) => {
            if (data.Result == "Deleted") {
              setDataRows(dataRows.filter((row: any) => row.ID !== id));
            } else {
              Swal.fire(
                "Error deleting, make sure is not in use!",
                "",
                "error"
              );
            }
          })
          .catch((e: any) => {
            console.log(e.response.data);
            if (e.response.data?.["APIException.RedOchre.Cloud"]) {
              Swal.fire(
                e?.response?.data?.["APIException.RedOchre.Cloud"],
                "",
                "info"
              );
              return;
            }
            handleErrorResponse(e);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setDataRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setDataRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow: any = dataRows.find((row: any) => row.ID === id);
    if (editedRow!.isNew) {
      setDataRows(dataRows.filter((row: any) => row.ID !== id));
    }
  };
  interface EditToolbarProps {
    setData: (setData: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setDataRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }
  //#endregion

  const fetchData = async (Tab: string) => {
    if (
      miningCompanySelectorID == "0" &&
      contractSelectorID == "0" &&
      drillingProgramSelectorID == "0"
    ) {
      return;
    }

    if (drillingProgramSelectorID != "0") {
      let url = `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}`;
      if (Tab === "Task") {
        url += "/Task/~GraphQL";
        getTaskList(url);
        return;
      } else if (Tab == "PerMRates" && bitSizeId > "0") {
        url += `/DPBitSize/ID_${bitSizeId}/DrillingCharges`;
      } else {
        url += `/${Tab.replace("DPBitSize", "~DPBitSize_GetValues")}`;
      }

      Api.post(url)
        .then(({ data }) => {
          try {
            setDataRows(data);
            return;
          } catch {
            setDataRows([]);
          }
        })
        .catch((e) => {
          console.log(e, "eror o line 661");
          setDataRows([]);
        });
    } else {
      setDataRows([]);
    }
  };

  const getTaskList = (url: string) => {
    const payload = `
      {
          Task {
            ID 
            ID_AP_Plod  
            ID_EDP_Drilling_Program  
            ID_EA_Task  
            ID_EDP_Task  
            ID_EDP_Task_ChargeType   
            Fixed_Cost   
            ChargeType_Locked  
            Task_Metric   
            taskmetric {
              ID 
              TaskMetric 
              ID_EA_TaskMetric 
              ID_AP_Plod 
            }
          }
        }
        `;
    Api.post(url, payload)
      .then(({ data }) => {
        try {
          setDataRows(data?.data?.Task);
          return;
        } catch {
          setDataRows([]);
        }
      })
      .catch((e) => {
        console.log(e, "eror o line 661");
        setDataRows([]);
      });
  };

  //#region COLUMNS FOR DATAGRID:

  let contractSetupColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field:
        optionsTabValue == "DPRig"
          ? "ID_EA_Rig"
          : optionsTabValue == "Site"
            ? "ID_EA_Site"
            : optionsTabValue == "DPPersonnel"
              ? "ID_EA_Personnel"
              : optionsTabValue == "DPBitSize"
                ? "ID_EA_BitSize"
                : "ID_EA_Task",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions:
        optionsTabValue == "DPRig"
          ? rigOptions
          : optionsTabValue == "Site"
            ? siteOptions
            : optionsTabValue == "DPPersonnel"
              ? personnelOptions
              : optionsTabValue == "DPBitSize"
                ? bitSizeOptions
                : taskOptions,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  let contractCostColumns: GridColDef<any>[] = [
    {
      headerName:
        optionsTabValue == "TaskChargeType"
          ? "Charge Type"
          : optionsTabValue == "MonthlyCharges"
            ? "Monthly Charge Name" :
            optionsTabValue == "OneOffCharges" ?
              " One Off Charge Name"
              : "Shift Charge Name",
      field:
        optionsTabValue == "TaskChargeType"
          ? "ChargeType"
          : optionsTabValue == "MonthlyCharges"
            ? "Monthly_Charge_Name" :
            optionsTabValue == "OneOffCharges" ?
              "One_Off_Charge_Name"
              : "Shift_Charge_Name",
      flex: 1,
      editable: true,
    },
    {
      headerName:
        optionsTabValue == "TaskChargeType"
          ? "Hourly Rate"
          : optionsTabValue == "MonthlyCharges"
            ? "Monthly Charge" :
            optionsTabValue == "OneOffCharges" ?
              " One Off Charge"
              : "Shift Charge",
      field:
        optionsTabValue == "TaskChargeType"
          ? "Hourly_Rate"
          : optionsTabValue == "MonthlyCharges"
            ? "Monthly_Charge" :
            optionsTabValue == "OneOffCharges" ?
              "One_Off_Charge"
              : "Shift_Charge",
      flex: 1,
      editable: true,
      type: "number",
      valueGetter: (params) =>
        "$" +
        (
          Math.round(
            (optionsTabValue == "TaskChargeType"
              ? params.row.Hourly_Rate
              : optionsTabValue == "MonthlyCharges"
                ? params.row.Monthly_Charge :
                optionsTabValue == "OneOffCharges" ?
                  params.row.One_Off_Charge
                  : params.row.Shift_Charge) * 100
          ) / 100
        ).toFixed(2),
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Day",
      field: "Day",
      flex: 1,
      editable: true,
      type: "boolean",
    },
    {
      headerName: "Night",
      field: "Night",
      flex: 1,
      editable: true,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  let PerMRateColunms: GridColDef<any>[] = [
    {
      headerName: "From",
      field: "From_Meter",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      headerName: "TO",
      field: "To_Meter",
      flex: 1,
      editable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Charge Per Meter",
      field: "Charge_Per_Meter",
      flex: 1,
      editable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  let personnelColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field: "ID_EA_Personnel",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: personnelOptions,
    },
    {
      headerName: "Type",
      field: "ID_EA_PersonnelType",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: personnelTypeOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  let consumableColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field: "ID_EA_Consumable",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: consumableOptions,
    },
    {
      headerName: "Charge",
      field: "Charge",
      flex: 1,
      editable: true,
      type: "number",
      valueGetter: (params) =>
        "$" + (Math.round(params.row.Charge * 100) / 100).toFixed(2),
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Default Charge",
      field: "Default_Charge",
      flex: 1,
      editable: true,
      type: "boolean",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  let taskColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field: "ID_EA_Task",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: taskOptions,
    },
    {
      headerName: "Charge Rate",
      field: "ID_EDP_Task_ChargeType",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: chargeTypeOptions,
    },
    {
      headerName: "Fixed Price",
      field: "Fixed_Cost",
      flex: 1,
      editable: true,
      type: "number",
      valueGetter: (params) => {
        const fixedCost = params.row.Fixed_Cost;
        if (fixedCost === null || fixedCost === undefined) {
            return 0.00;  // Display $0.00 for null or undefined Fixed_Cost
        } else {
            return "$" + (Math.round(fixedCost * 100) / 100).toFixed(2);
        }
    },
    
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Charge Type Locked",
      field: "ChargeType_Locked",
      flex: 1,
      editable: true,
      type: "boolean",
      headerAlign: "left",
      align: "left",
    },
    {
      headerName: "Task Metric",
      field: "Task_Metric",
      flex: 1,
      editable: true,
      headerAlign: "left",
      align: "left",
      type: "singleSelect",
      valueOptions: taskTypeOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  //#endregion

  React.useEffect(() => {
    fetchData(optionsTabValue);
  }, [optionsTabValue, drillingProgramSelectorID]);

  //#region Custom Toolbar (For Tabs)
  interface EditToolbarProps {
    setDataRows: (newDataRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setDataRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function CustomToolbar(props: EditToolbarProps) {
    const { setDataRows, setDataRowModesModel } = props;
    const handleAddClick = () => {
      const ID = randomId();
      setDataRows((oldRows: any) => [
        ...oldRows,
        { ID, isNew: true, Default_Charge: false },
      ]);
      setDataRowModesModel((oldModel: any) => ({
        ...oldModel,
        [ID]: { mode: GridRowModes.Edit },
      }));
    };

    return (
      <>
        <GridToolbarContainer
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* Option Selectors */}
          {/* Contract Setup Contents */}
          {modeTabValue === "ContractSetup" && (
            <Box sx={{ borderBottom: 1, marginTop: 2, borderColor: "divider" }}>
              <Tabs
                value={contractSetupTabValue}
                onChange={handleContractSetupTabChange}
              >
                <Tab value="DPRig" label="Rigs" />
                <Tab value="DPConsumable" label="Consumables" />
                <Tab value="Site" label="Sites" />
                <Tab value="DPPersonnel" label="Operators" />
                <Tab value="Task" label="Task" />
                <Tab value="DPBitSize" label="Bit Size" />
              </Tabs>
            </Box>
          )}

          {/* Contract Cost Contents */}
          {modeTabValue === "ContractCost" && (
            <Box sx={{ borderBottom: 1, marginTop: 2, borderColor: "divider" }}>
              <Tabs
                value={contractCostTabValue}
                onChange={handleContractCostTabChange}
              >
                <Tab value="TaskChargeType" label="Task Charge Rate" />
                <Tab value="MonthlyCharges" label="Monthly Charges" />
                <Tab value="OneOffCharges" label="One Off Charges" />
                <Tab value="ShiftCharges" label="Shift Charges" />
                <Tab value="PerMRates" label="Per M Rates" />
              </Tabs>

              {/* add filter for bitSize */}

              {contractCostTabValue == "PerMRates" && (
                <>
                  <Box sx={{ marginBottom: 2, marginLeft: 2 }}>
                    <FormHelperText>Bit Size</FormHelperText>
                    <Select
                      id="bitSizeDataId"
                      value={bitSizeId}
                      onChange={handleBitSizeSelectorChange}
                      style={{ width: 250 }}
                    >
                      {bitSizeData.length == 0 ? (
                        <MenuItem value="0">No Bit Sizes</MenuItem>
                      ) : (
                        bitSizeData?.map((data: any): JSX.Element => {
                          return (
                            <MenuItem key={data.ID} value={data.ID}>
                              {data.bitSize}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </Box>
                </>
              )}
            </Box>
          )}

          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddClick}
          >
            Add Item
          </Button>
        </GridToolbarContainer>
        <hr />
      </>
    );
  }

  //#endregion

  //#region contract settings
  const [shiftTypeSelection, setShiftTypeSelection] =
    React.useState<string>("DoubleShift");
  const handleShiftTypeSelectorChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    setShiftTypeSelection(event.target.value);
  };

  const [collectBitChecked, setCollectBitChecked] =
    React.useState<boolean>(false);

  const handleCollectBitChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCollectBitChecked(event.target.checked);
  };

  const [collectBitTypeChecked, setCollectBitTypeChecked] =
    React.useState<boolean>(false);

  const handleCollectBitTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCollectBitTypeChecked(event.target.checked);
  };

  const [timedTasksChecked, setTimedTasksChecked] =
    React.useState<boolean>(false);

  const handleTimedTasksChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTimedTasksChecked(event.target.checked);
  };

  // get Drilling Charges
  const getDrillingCharges = async () => {
    if (bitSizeId != "0" && drillingProgramSelectorID != "0") {
      await Api.post(
        `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}/DPBitSize/ID_${bitSizeId}/DrillingCharges`
      )
        .then(({ data }) => {
          try {
            setDataRows(data);
          } catch {
            setDataRows([]);
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    } else {
      setDataRows([]);
    }
  };

  React.useEffect(() => {
    getDrillingCharges();
  }, [bitSizeId]);

  //#rendregion

  return (
    <>
      {/* Data Selectors */}
      <div style={{ margin: 20 }}>
        <FormHelperText>Mining Company</FormHelperText>
        <Select
          id="MiningCompanySelector"
          value={miningCompanySelectorID}
          onChange={handleMiningCompanySelectorChange}
          style={{ width: 250 }}
        >
          {miningCompanyData.length == 0 ? (
            <MenuItem value="0">No Mining Companys</MenuItem>
          ) : (
            miningCompanyData.map((data: any): JSX.Element => {
              return (
                <MenuItem
                  key={data["ID_EA_MiningCompany"]}
                  value={data["ID_EA_MiningCompany"]}
                >
                  {data["MiningCompanyName"]}
                </MenuItem>
              );
            })
          )}
        </Select>
      </div>
      <div style={{ display: "flex", margin: 20 }}>
        <div>
          <FormHelperText>Contract</FormHelperText>
          <Select
            id="ContractSelector"
            value={contractSelectorID}
            onChange={handleContractSelectorChange}
            style={{ width: 250 }}
          >
            {contractData.length == 0 ? (
              <MenuItem value="0">No Contracts</MenuItem>
            ) : (
              contractData.map((data: any): JSX.Element => {
                return (
                  <MenuItem
                    key={data["ID_EC_Contract"]}
                    value={data["ID_EC_Contract"]}
                  >
                    {data["ContractName"]}
                  </MenuItem>
                );
              })
            )}
          </Select>
        </div>

        <div style={{ marginLeft: 20 }}>
          <FormHelperText>Drilling Program</FormHelperText>
          <Select
            id="drillingProgramSelector"
            value={drillingProgramSelectorID}
            onChange={handleDrillingProgramSelectorChange}
            style={{ width: 250 }}
          >
            {drillingProgramData.length == 0 ? (
              <MenuItem value="0">No Drilling Programs</MenuItem>
            ) : (
              drillingProgramData.map((data: any): JSX.Element => {
                return (
                  <MenuItem
                    key={data["ID_EDP_Drilling_Program"]}
                    value={data["ID_EDP_Drilling_Program"]}
                  >
                    {data["DrillingProgramName"]}
                  </MenuItem>
                );
              })
            )}
          </Select>
        </div>
      </div>

      <div style={{ marginLeft: 20 }}>
        <Accordion
          sx={{
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            width: 520,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Contract Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div>
                <FormHelperText>Shift Type</FormHelperText>
                <Select
                  id="ShiftSelector"
                  value={shiftTypeSelection}
                  onChange={handleShiftTypeSelectorChange}
                  style={{ width: 250 }}
                >
                  <MenuItem key="DoubleShift" value="DoubleShift">
                    Double Shift
                  </MenuItem>
                  <MenuItem key="DayShift" value="DayShift">
                    Day Shift
                  </MenuItem>
                  <MenuItem key="NightShift" value="NightShift">
                    Night Shift
                  </MenuItem>
                </Select>
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={collectBitTypeChecked}
                      onChange={handleCollectBitTypeChange}
                    />
                  }
                  label="Collect BitType on plodsheet"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={collectBitChecked}
                      onChange={handleCollectBitChange}
                    />
                  }
                  label="Collect Bit on plodsheet"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={timedTasksChecked}
                      onChange={handleTimedTasksChange}
                    />
                  }
                  label="Timed Tasks"
                />
              </div>

              <Button variant="outlined">Save Changes</Button>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div style={drillingProgramSelectorID == "0" ? { display: "none" } : {}}>
        <div style={{ margin: 20 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={modeTabValue} onChange={handleModeTabChange}>
              <Tab value="ContractSetup" label="Contract Setup" />
              <Tab value="ContractCost" label="Contract Cost" />
            </Tabs>
          </Box>
        </div>
      </div>

      {/* The Data Grid */}
      <Box
        sx={{
          height: "60vh",
          margin: "10px",
          border: 1,
          borderColor: "#DEDEDE",
          borderRadius: 2,
        }}
      >
        {/*  */}
        <DataGrid
          sx={{ zIndex: 0 }}
          // columns={={optionsTabValue == "~DPTask_GetValues" ? taskColumns : optionsTabValue == "~DPPersonnel_GetValues" ? personnelColumns : optionsTabValue == "MonthlyCharges" ? monthlyChargesColumns : optionsTabValue == "TaskChargeType" ? taskChargeTypeColumns : secondColumns}}
          columns={
            modeTabValue == "ContractCost"
              ? contractCostTabValue == "PerMRates"
                ? PerMRateColunms
                : contractCostColumns
              : optionsTabValue == "DPPersonnel"
                ? personnelColumns
                : optionsTabValue == "Task"
                  ? taskColumns
                  : optionsTabValue == "DPConsumable"
                    ? consumableColumns
                    : contractSetupColumns
          }
          rows={dataRows}
          disableRowSelectionOnClick
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          isCellEditable={(params) => params.row.isNew} //This makes it not editable unless its new.
          getRowId={(row) => row.ID}
          editMode="row"
          columnVisibilityModel={{
            Day: optionsTabValue == "ShiftCharges",
            Night: optionsTabValue == "ShiftCharges",
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: { setDataRows, setDataRowModesModel },
          }}
        />
      </Box>
    </>
  );
};

export default ContractsSetup;

import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../api/api";

import Avatar from "@mui/material/Avatar";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";

const AppLayout = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [access, setAccess] = useState<any>(null);
  var plod_id = sessionStorage.getItem("plod_id");

  const plodId = useSelector((state: RootState) => state.plodData.plod_id);
  const plod = plodId || plod_id;

  const getAccess = async () => {
    const payload = ` {
            LoggedInTeamMember() {
              firstname
              lastname
              ID
              Roles() {
                ID
                RoleName
              }
            }
          }`;

    try {
      const { data, status } = await Api.post(
        `api/Plods/ID_${plod}/LoggedInTeamMember/~GraphQL`,
        payload
      );

      if (status == 200) {
        const loginMember: any = data?.data?.LoggedInTeamMember[0] || null;
        setAccess(loginMember);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (plod_id) {
      getAccess();
    }
  }, [plod_id]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = "https://login.redochre.cloud";
  };

  return (
    <div
      style={{
        padding: "0px 0px 0px 250px",
      }}
    >
      <Sidebar />

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingRight: 15,
          background: "#ffffff",
        }}
      >
        <IconButton color="inherit" onClick={handleClick}>
          <Avatar>{access?.firstname?.charAt(0) || "M"}</Avatar>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem>{access?.firstname}</MenuItem>
          <MenuItem>{access?.Roles?.[0]?.RoleName}</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
      <Outlet />
    </div>
  );
};

export default AppLayout;

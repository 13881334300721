import React from "react";
import { Box, Button, Tab, Tabs } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
} from "@mui/x-data-grid";
import renameKey from "../functions/renameKey";
import renameTwoKey from "../functions/renameTwoKeys";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { randomId } from "@mui/x-data-grid-generator";
import dayjs from "dayjs";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Api from "../api/api";
import { handleErrorResponse } from "../api/apiErrorhandle";

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Accept", "*/*");
const Lists: React.FunctionComponent = () => {
  document.title = "Lists" + " - " + sessionStorage.getItem("PlodName");
  var plod_id = sessionStorage.getItem("plod_id");
  if (plod_id == "" || plod_id == null) {
    window.location.replace(
      "https://login.redochre.cloud/"
    );
  }
  const [data, setData] = React.useState<any>([]);
  const [locationOptions, setLocationOptions] = React.useState<any>([]);

  const [tabValue, setTabValue] = React.useState("Personnel");

  //#region Shared Table Data
  const [dataRowModesModel, setDataRowModesModel] =
    React.useState<GridRowModesModel>({});

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: "Do you want to Delete this row?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result: any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const raw = JSON.stringify({ ID: id });

        Api.post(`/api/Plods/ID_${plod_id}/${tabValue}/~DeleteItem`, raw)
          .then(({ data }: any) => {
            if (data.Result == "Deleted") {
              setData((prev: any) => {
                return prev?.filter((row: any) => row.id !== id);
              });
            } else {
              Swal.fire(
                "Error deleting, make sure is not in use!",
                "",
                "error"
              );
            }
          })
          .catch((e) => {
            handleErrorResponse(e);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };
  const handleSaveClick = (id: GridRowId) => () => {
    setDataRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setDataRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow: any = data.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      setData(data.filter((row: any) => row.id !== id));
    }
  };
  const handleEditClick = (id: GridRowId) => () => {
    setDataRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setDataRowModesModel(newRowModesModel);
  };
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params: any,
    event: any
  ) => {
    event.defaultMuiPrevented = true;
  };
  interface EditToolbarProps {
    setData: (setData: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setDataRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }
  //#endregion

  React.useEffect(() => {
    const fetchLocationsData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/Locations`)
        .then(({ data, status }: any) => {
          console.log(data, status);
          if (status == 200) {
            setLocationOptions(
              data.map((obj: any) => {
                return {
                  label: obj.LocationName,
                  value: obj.ID_EA_Location,
                };
              })
            );
          }
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title:
              e?.response?.data?.["Exception.RedOchre.Cloud"] ??
              "Something went wrong.",
          });
        });
    };
    fetchLocationsData();
  }, [tabValue == "Site"]);

  //#region Row updates
  const processRowUpdate = async (newRow: GridRowModel) => {
    let updatedRow: any = {};
    var raw: string = "";
    if (tabValue == "Consumables") {
      if (newRow.ConsumableName == "" || newRow.ConsumableName == " ") {
        swal({
          title: "Error",
          text: "Consumable Name cannot be empty.",
          icon: "error",
          dangerMode: true,
        });
      }
      raw = JSON.stringify({
        ID: newRow.id,
        ConsumableName: newRow.ConsumableName,
      });
    }

    if (tabValue == "TaskMetric") {
      if (newRow.TaskMetric == "" || newRow.TaskMetric == " ") {
        swal({
          title: "Error",
          text: "Task Metric Name cannot be empty.",
          icon: "error",
          dangerMode: true,
        });
      }
      raw = JSON.stringify({ ID: newRow.id, TaskMetric: newRow.TaskMetric });
    }
    if (tabValue == "Tasks") {
      if (newRow.TaskName == "" || newRow.TaskName == " ") {
        swal({
          title: "Error",
          text: "Task Name cannot be empty.",
          icon: "error",
          dangerMode: true,
        });
      }
      raw = JSON.stringify({ ID: newRow.id, TaskName: newRow.TaskName });
    }

    if (tabValue == "Locations") {
      if (newRow.LocationName == "" || newRow.LocationName == " ") {
        swal({
          title: "Error",
          text: "Location Name cannot be empty.",
          icon: "error",
          dangerMode: true,
        });
      }
      raw = JSON.stringify({
        ID: newRow.id,
        LocationName: newRow.LocationName,
      });
    }
    if (tabValue == "PersonnelType") {
      if (newRow.PersonnelTypeName == "" || newRow.PersonnelTypeName == " ") {
        swal({
          title: "Error",
          text: "Personnel Type Name cannot be empty.",
          icon: "error",
          dangerMode: true,
        });
      }
      raw = JSON.stringify({
        ID: newRow.id,
        PersonnelTypeName: newRow.PersonnelTypeName,
      });
    }

    if (tabValue == "Personnel") {
      if (newRow.FirstName == "" || newRow.FirstName == " ") {
        swal({
          title: "Error",
          text: "First Name cannot be empty.",
          icon: "error",
          dangerMode: true,
        });
      } else if (newRow.LastName == "" || newRow.LastName == " ") {
        swal({
          title: "Error",
          text: "Last Name cannot be empty.",
          icon: "error",
          dangerMode: true,
        });
      }

      raw = JSON.stringify({
        ID: newRow.id,
        FirstName: newRow.FirstName,
        LastName: newRow.LastName,
        DateOfBirth: newRow.DateOfBirth,
      });
    }

    if (tabValue == "DrillingTypes") {
      if (newRow.DrillingType == "" || newRow.DrillingType == " ") {
        swal({
          title: "Error",
          text: "Drilling Type cannot be empty.",
          icon: "error",
          dangerMode: true,
        });
      } else if (
        newRow.DrillingTypeCode == "" ||
        newRow.DrillingTypeCode == " "
      ) {
        swal({
          title: "Error",
          text: "Drilling Type Code cannot be empty.",
          icon: "error",
          dangerMode: true,
        });
      }

      raw = JSON.stringify({
        ID: newRow.id,
        DrillingType: newRow.DrillingType,
        DrillingTypeCode: newRow.DrillingTypeCode,
      });
    }
    if (tabValue == "Site") {
      if (newRow.SiteName == "" || newRow.SiteName == " ") {
        swal({
          title: "Error",
          text: "Site Name cannot be empty.",
          icon: "error",
          dangerMode: true,
        });
      } else if (newRow.ID_EA_Location == "" || newRow.ID_EA_Location == " ") {
        swal({
          title: "Error",
          text: "Location cannot be empty",
          icon: "error",
          dangerMode: true,
        });
      }

      raw = JSON.stringify({
        ID: newRow.ID_EA_Site,
        SiteName: newRow.SiteName,
        ID_EA_Location: newRow.ID_EA_Location,
      });
    }

    if (newRow!.isNew) {
      await Api.post(`/api/Plods/ID_${plod_id}/${tabValue}/~AddItem`, raw)
        .then(({ data }: any) => {
          if (data.ID) {
            updatedRow = { ...newRow, isNew: false, id: data.ID };
            setData((prev: any) => {
              return prev?.map((row: any) =>
                row.id === newRow.id ? updatedRow : row
              );
            });
          } else {
            swal({
              title: "Error",
              text: "Failed to add data.",
              icon: "error",
              dangerMode: true,
            });
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    } else {
      await Api.post(`/api/Plods/ID_${plod_id}/${tabValue}/~UpdateItem`, raw)
        .then((datad) => {
          updatedRow = { ...newRow, isNew: false };
          setData(
            data.map((row: any) => (row.id === newRow.id ? updatedRow : row))
          );
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    }
    return updatedRow;
  };

  function EditDataToolbar(props: EditToolbarProps) {
    const { setData, setDataRowModesModel } = props;

    const handleDataClick = () => {
      const id = randomId();
      setData((oldRows: any) => [
        ...oldRows,
        { id, ConsumableName: "", isNew: true },
      ]);
      setDataRowModesModel((oldModel: any) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "ConsumableName" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          style={{ marginLeft: "auto", marginRight: 0 }}
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleDataClick}
        >
          Add data
        </Button>
      </GridToolbarContainer>
    );
  }
  //#endregion

  //#region Columns
  let singleColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field:
        tabValue == "Consumables"
          ? "ConsumableName"
          : tabValue == "PersonnelType"
            ? "PersonnelTypeName"
            : tabValue == "Tasks"
              ? "TaskName"
              : tabValue == "TaskMetric"
                ? "TaskMetric"
                : "LocationName",
      editable: true,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  let doubleColumns: GridColDef<any>[] = [
    {
      headerName: "Drilling Type",
      field: "DrillingType",
      editable: true,
      flex: 0.5,
    },
    {
      headerName: "Drilling Type Code",
      field: "DrillingTypeCode",
      editable: true,
      flex: 0.5,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  let tripleColumns: GridColDef<any>[] = [
    {
      headerName: "First Name",
      field: "FirstName",
      editable: true,
      flex: 0.3,
    },
    {
      headerName: "Last Name",
      field: "LastName",
      editable: true,
      flex: 0.3,
    },
    {
      headerName: "Date Of Birth",
      field: "DateOfBirth",
      editable: true,
      flex: 0.3,
      type: "date",
      valueFormatter: (params) => dayjs(params.value).format("MM/DD/YYYY"),
      valueGetter: ({ value }) => value && dayjs(value),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  let siteColumns: GridColDef<any>[] = [
    {
      headerName: "Site Name",
      field: "SiteName",
      editable: true,
      flex: 0.5,
    },
    {
      headerName: "Location Name",
      field: "ID_EA_Location",
      flex: 0.5,
      editable: true,
      type: "singleSelect",
      valueOptions: locationOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  //#endregion

  //#region Tab Data
  const fetchData = async (Tab: string) => {
    await Api.post(`/api/Plods/ID_${plod_id}/${Tab}`)
      .then(({ data }) => {
        try {
          data?.forEach((obj: any) => renameKey(obj, "ID", "id"));
          setData(data);
        } catch (err) {
          setData([]);
        }
      })
      .catch((e) => {
        setData([]);
      });
  };
  React.useEffect(() => {
    fetchData(tabValue);
  }, []);
  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTabValue(newTab);
    fetchData(newTab);
  };
  //#endregion

  //#endregion

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          style={{ marginLeft: 20, marginRight: 20 }}
          variant="fullWidth"
          value={tabValue}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="Personnel" label="Operators" />
          <Tab value="PersonnelType" label="Personnel Type" />
          <Tab value="Locations" label="Locations" />
          <Tab value="Tasks" label="Tasks" />
          <Tab value="TaskMetric" label="Task Metric" />
          <Tab value="Consumables" label="Consumables" />
          <Tab value="DrillingTypes" label="Drilling Types" />
          <Tab value="Site" label="Sites" />
        </Tabs>
      </Box>

      <Box
        sx={{
          height: "80vh",
          margin: "10px",
          border: 1,
          borderColor: "#DEDEDE",
          borderRadius: 2,
        }}
      >
        <DataGrid
          sx={{ zIndex: 0 }}
          columns={
            tabValue == "Site"
              ? siteColumns
              : tabValue == "DrillingTypes"
                ? doubleColumns
                : tabValue == "Personnel"
                  ? tripleColumns
                  : singleColumns
          }
          rows={data}
          editMode="row"
          pagination
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          disableRowSelectionOnClick
          slots={{
            toolbar: EditDataToolbar,
            loadingOverlay: LinearProgress,
          }}
          slotProps={{
            toolbar: { setData, setDataRowModesModel },
          }}
        />
      </Box>
    </>
  );
};

export default Lists;

import React, { FC } from 'react';
import "./../styles.css";
import { Button } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, GridRowsProp, GridToolbarContainer, MuiEvent } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import renameKey from '../functions/renameKey';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Api from '../api/api';
import { handleErrorResponse } from '../api/apiErrorhandle';
const myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');
myHeaders.append('Accept', '*/*');

const Rigs: React.FunctionComponent = () => {

  document.title = "Rigs" + " - " + sessionStorage.getItem("PlodName");
  var plod_id = sessionStorage.getItem("plod_id");
  if (plod_id == "" || plod_id == null) {
    window.location.replace(process.env.REACT_APP_INVALID_TOKEN_REDIRECT_URL || "https://redochre.com/");
  };

  const fetchData = async () => {


    await Api.post(`/api/Plods/ID_${plod_id}/Rigs`,)
      .then(({ data }) => {
        if (data.length != 0) {
          data.forEach((obj: any) => renameKey(obj, 'ID', 'id'));
          setRigsRows(data);
        }
        else {
          setRigsRows([]);
        }
      }).catch((e) => {
        handleErrorResponse(e)

      })
  }
  React.useEffect(() => {

    fetchData();
  }, []);

  //FOR DATA GRID processing.
  const [rigsRows, setRigsRows] = React.useState<any>([]);
  const [rigsRowModesModel, setRigsRowModesModel] = React.useState<GridRowModesModel>({});

  const handleRigsEditClick = (id: GridRowId) => () => {
    setRigsRowModesModel({ ...rigsRowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleRigsSaveClick = (id: GridRowId) => () => {
    setRigsRowModesModel({ ...rigsRowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleRigsDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: 'Do you want to Delete this row?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result: any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const raw = JSON.stringify({ "ID": id });

        Api.post(`/api/Plods/ID_${plod_id}/Rigs/~DeleteItem`, raw)
          .then(({ data }: any) => {
            if (data.Result == 'Deleted') {
              setRigsRows(rigsRows.filter((row: any) => row.id !== id));
            }
            else {
              Swal.fire('Error deleting, make sure is not in use!', '', 'error');
            };
          }).catch((e) => {
            handleErrorResponse(e)

          })
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      };
    });
  };

  const handleRigsCancelClick = (id: GridRowId) => () => {
    setRigsRowModesModel({
      ...rigsRowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow: any = rigsRows.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      setRigsRows(rigsRows.filter((row: any) => row.id !== id));
    };
  };
  const processRigsRowUpdate = async (newRow: GridRowModel) => {
    let updatedRow: any = {};

    var raw = JSON.stringify({
      "ID": newRow.id,
      "RigName": newRow.RigName,
    });

    if (newRow!.isNew) {
      await Api.post(`/api/Plods/ID_${plod_id}/Rigs/~AddItem`, raw)
        .then(({ data }: any) => {
          if (data.ID) {
            updatedRow = { ...newRow, isNew: false, id: data.ID };
            setRigsRows(rigsRows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
          }
          else {
            swal({
              title: "Error",
              text: `Failed adding data!`,
              icon: "error",
              dangerMode: true,
            })
            updatedRow = {};
          }
        }).catch((e) => {
          handleErrorResponse(e)

        })
    }
    else {
      await Api.post(`/api/Plods/ID_${plod_id}/Rigs/~UpdateItem`, raw)
        .then(({ data }) => {
          updatedRow = { ...newRow, isNew: false };
          setRigsRows(rigsRows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));

        }).catch((e) => {
          handleErrorResponse(e)


        })
    };
    return updatedRow;
  };

  const handleRigsRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRigsRowModesModel(newRowModesModel);
    fetchData();
  };

  const handleRigsRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRigsRowEditStop: GridEventListener<'rowEditStop'> = (params: any, event: any) => {
    event.defaultMuiPrevented = true;
  };

  interface EditToolbarProps {
    setRigsRows: (newRigsRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRigsRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  };
  function EditRigsToolbar(props: EditToolbarProps) {
    const { setRigsRows, setRigsRowModesModel } = props;

    const handleRigsClick = () => {
      const id = randomId();
      setRigsRows((oldRows: any) => [...oldRows, { id, RigName: '', isNew: true }]);
      setRigsRowModesModel((oldModel: any) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'RigName' },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleRigsClick}>
          Add Rig
        </Button>
      </GridToolbarContainer>
    );
  };





  return (
    <>
      <Box sx={{ height: "90vh", margin: "10px", border: 1, borderColor: '#DEDEDE', borderRadius: 2 }}>
        <DataGrid sx={{ zIndex: 0 }}
          columns={[
            {
              field: 'RigName',
              headerName: "Rig Name",
              editable: true,
              flex: 1
            },
            {
              field: 'actions',
              type: 'actions',
              headerName: 'Actions',
              width: 100,
              cellClassName: 'actions',
              getActions: ({ id }) => {
                const isInEditMode = rigsRowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                  return [
                    <GridActionsCellItem
                      icon={<SaveIcon />}
                      label="Save"
                      onClick={handleRigsSaveClick(id)}
                    />,
                    <GridActionsCellItem
                      icon={<CancelIcon />}
                      label="Cancel"
                      className="textPrimary"
                      onClick={handleRigsCancelClick(id)}
                      color="inherit"
                    />,
                  ];
                }

                return [
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleRigsEditClick(id)}
                    color="inherit"
                  />,
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleRigsDeleteClick(id)}
                    color="inherit"
                  />,
                ];
              }
            }

          ]}
          rows={rigsRows}
          editMode='row'
          rowModesModel={rigsRowModesModel}
          onRowModesModelChange={handleRigsRowModesModelChange}
          onRowEditStart={handleRigsRowEditStart}
          onRowEditStop={handleRigsRowEditStop}
          processRowUpdate={processRigsRowUpdate}
          disableRowSelectionOnClick
          slots={{
            toolbar: EditRigsToolbar,
          }}
          slotProps={{
            toolbar: { setRigsRows, setRigsRowModesModel },
          }}
        />
      </Box>

    </>
  );
}

export default Rigs



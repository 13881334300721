import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "./../components/LoadingScreen";
import Api from "../api/api";
import { useDispatch } from "react-redux";
import { stateData } from "../feature/plodSlice";
const Home: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  document.title = "Home" + " - " + sessionStorage.getItem("PlodName");
  var plod_id = sessionStorage.getItem("plod_id");

  //Getting the URL Parameters
  const search: string = useLocation().search;
  const searchParams: URLSearchParams = new URLSearchParams(search);
  const LoginToken: string = searchParams.get("LoginToken") || "";
  if (LoginToken) {
    sessionStorage.setItem("loginToken", LoginToken);
  }
  const [loading, setLoading] = React.useState(true);
  const [PlodName, setPlodName] = React.useState("");

  function processStuff() {
    if (LoginToken != "" && loading) {
      try {
        return Api.post(`/api/Plods/~Start`)
          .then(({ data }) => {
            dispatch(stateData(data));

            sessionStorage.setItem("plod_id", data.ID);
            sessionStorage.setItem(
              "PlodName",
              data.Plod_App_DrillingCompanyName
            );
            setLoading(false);
          })
          .catch((e) => {
            window.location.replace("https://login.redochre.cloud/");
          });
      } catch {
        window.location.replace("https://login.redochre.cloud/");
      }
    }
    setPlodName(sessionStorage.getItem("PlodName") || "");
    setLoading(false);
  }

  React.useEffect(() => {
    processStuff();
  }),
    [];

  return (
    <>
      <h2 style={{ textAlign: "center", marginTop: "15px" }}>{PlodName}</h2>
      <LoadingSpinner show={loading} />
    </>
  );
};

export default Home;

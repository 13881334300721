import axios, { AxiosInstance, AxiosResponse } from "axios";

// Create an Axios instance with a base URL
const Api: AxiosInstance = axios.create({
  baseURL: `https://api.drilldown.redochre.cloud`,
  // baseURL: `http://localhost:44393`,

});

Api.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,
      LoginToken: sessionStorage.getItem("loginToken"),
      "Content-Type": "text/plain",
    };

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response: AxiosResponse) => {
    // You can handle successful responses here
    return response;
  },
  (error: any) => {
    // You can handle errors here (e.g., display an error message)

    if (error.response && error.response.status === 401) {
      // Handle the specific error message
      // Clear local storage and redirect to the login page
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("https://login.redochre.cloud/");
    }
    return Promise.reject(error);
  }
);

export default Api;

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormGroup,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
  selectedGridRowsCountSelector,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";

import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import renameKey from "../functions/renameKey";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { randomId } from "@mui/x-data-grid-generator";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import Api from "../api/api";
import { handleErrorResponse } from "../api/apiErrorhandle";
import LoadingSpinner from "../components/LoadingScreen";

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Accept", "*/*");

const Teams: React.FunctionComponent = () => {
  document.title = "Teams" + " - " + sessionStorage.getItem("PlodName");
  var plod_id = sessionStorage.getItem("plod_id");
  if (plod_id == "" || plod_id == null) {
    window.location.replace("https://login.redochre.cloud/");
  }

  const [selectedTab, setSelectedTab] = React.useState<string>("roles");
  const [selectedRole, setSeletedRole] = React.useState(null);
  const [roleMenu, setRoleMenu] = React.useState([]);
  const [miningList, setMiningList] = React.useState([]);
  const [miningCardShow, setMiningCardShow] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isloading, setIsLoading] = React.useState(false);

  const [miningCard, setMiningCard] = React.useState(false);
  const [miningInactiveCard, setMiningInactiveCard] = React.useState(true);
  const [contractList, setContractList] = React.useState([]);

  const [rowID, setRowID] = React.useState("");

  const [roles, setRoles] = React.useState<any>([]);
  const [dropDownRole, setDropDownRoles] = React.useState<any>([]);
  const [selectedRowDataTeams, setSelectedRowDataTeams] =
    React.useState<any>(null);
  const [addmenuitems, setMenuItems] = React.useState([]);
  const [selectedTeamData, setSelectedTeamData] = React.useState<any>({});
  const [dataRowModesModel, setDataRowModesModel] =
    React.useState<GridRowModesModel>({});
  const [dataRows, setDataRows] = React.useState<any>([]);
  const [oneUSerData, setOneUserData] = React.useState<any>(null);

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setDataRowModesModel(newRowModesModel);
  };

  // code for card
  const [dataPrivileges, setDataPrivileges] = React.useState("");
  const [updateID, setUpdateID] = React.useState("");

  const [showAllMiningCompaniesCard, setShowAllMiningCompaniesCard] =
    React.useState(false);
  const [showNoMiningCompaniesCard, setShowNoMiningCompaniesCard] =
    React.useState(false);

  const [selectedMiningCompanies, setSelectedMiningCompanies] =
    React.useState("");
  const [selectedMiningContractCompanies, setSelectedMiningContractCompanies] =
    React.useState("");

  const handleRadioChange = (event: any) => {
    const selectedValue = event.target.value;

    const selectValue =
      selectedValue == "selectedMiningCompanies" ? "SELECTION" : selectedValue;
    setDataPrivileges(selectedValue);
    // Set visibility of cards and select box based on the selected radio value
    setShowAllMiningCompaniesCard(selectedValue == "ALL");
    setShowNoMiningCompaniesCard(selectedValue == "NONE");
    getCompanyUpdate(rowID, updateID, selectValue);
    getMiningCardShow(rowID, updateID);
  };

  const handleCloseCard = () => {
    // Close the relevant card based on the current dataPrivileges value
    if (dataPrivileges === "ALL" || dataPrivileges === "NONE") {
      setShowAllMiningCompaniesCard(false);
    }
  };

  const handleMiningCloseCard = () => {
    setMiningCard(false);
  };

  const handleMiningInactiveCloseCard = () => {
    setMiningInactiveCard(false);
  };

  const handleSelectChange = (event: any) => {
    setSelectedMiningCompanies(event.target.value);
  };

  const handleSelectContractChange = (event: any) => {
    setSelectedMiningContractCompanies(event.target.value);
  };

  const getMenuList = async (rowId: any) => {
    if (rowId == undefined) {
      return null;
    } else if (rowId !== undefined || rowId !== "undefined") {
      const headers = {
        LoginToken: "9508a91f-d366-49d9-b26c-daa6d9a248ea",
        "Content-Type": "text/plain",
      };
      try {
        setLoading(true);
        const response = await Api.post(
          `/api/Plods/ID_${plod_id}/TeamMembers/ID_${rowId}/~MenuItemsAvailable`,

          { headers }
        );
        const getAllMenu = response?.data;

        setRoleMenu(getAllMenu);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleErrorResponse(error);
      }
    }

    return null;
  };

  const getCompanyAccess = async (rowId: any) => {
    setRowID(rowId);
    if (rowId == undefined) {
      return null;
    } else if (rowId !== undefined || rowId !== "undefined") {
      const headers = {
        LoginToken: "9508a91f-d366-49d9-b26c-daa6d9a248ea",
        "Content-Type": "text/plain",
      };
      try {
        const response = await Api.post(
          `/api/Plods/ID_${plod_id}/TeamMembers/ID_${rowId}/CompanyAccess`,

          { headers }
        );
        const getAllMenu = response?.data;
        const radioData = getAllMenu[0]?.MiningCompanyAccess;
        const radioID = getAllMenu[0]?.ID;

        const radioButton =
          radioData == "SELECTION" ? "selectedMiningCompanies" : radioData;

        setDataPrivileges(radioButton);
        setUpdateID(radioID);
        setShowAllMiningCompaniesCard(radioButton == "ALL");
        setShowNoMiningCompaniesCard(radioButton == "NONE");
        await getMiningCardShow(rowId, radioID);
      } catch (error) {
        handleErrorResponse(error);
      }
    }
    return null;
  };

  const getCompanyUpdate = async (
    rowID: any,
    updateID: any,
    selectedValue: any
  ) => {
    const data = {
      MiningCompanyAccess: selectedValue,
      ID: updateID,
    };

    const headers = {
      LoginToken: "9508a91f-d366-49d9-b26c-daa6d9a248ea",
      "Content-Type": "text/plain",
    };
    try {
      const response = await Api.post(
        `/api/Plods/ID_${plod_id}/TeamMembers/ID_${rowID}/CompanyAccess/~UpdateItem`,
        data,

        { headers }
      );
      const getAllMenu = response?.data;
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const getMiningList = async () => {
    const headers = {
      LoginToken: "9508a91f-d366-49d9-b26c-daa6d9a248ea",
      "Content-Type": "text/plain",
    };
    try {
      const response = await Api.post(
        `/api/Plods/ID_${plod_id}/MiningCompany`,

        { headers }
      );
      const getAllMining = response?.data;

      setMiningList(getAllMining);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const getAddMiningList = async () => {
    if (selectedMiningCompanies !== "") {
      setIsLoading(true);
      const data = {
        ID_EA_MiningCompany: selectedMiningCompanies,
      };

      const headers = {
        LoginToken: "9508a91f-d366-49d9-b26c-daa6d9a248ea",
        "Content-Type": "text/plain",
      };
      try {
        const response = await Api.post(
          `/api/Plods/ID_${plod_id}/TeamMembers/ID_${rowID}/CompanyAccess/ID_${updateID}/MiningAccess/~AddItem`,
          data,

          { headers }
        );
        const getADDMiningList = response?.data;
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        handleErrorResponse(error);
      }
    }
  };

  const getMiningCardShow = async (rowID: any, updateId: any) => {
    const headers = {
      LoginToken: "9508a91f-d366-49d9-b26c-daa6d9a248ea",
      "Content-Type": "text/plain",
    };
    try {
      const response = await Api.post(
        `/api/Plods/ID_${plod_id}/TeamMembers/ID_${rowID}/CompanyAccess/ID_${updateId}/MiningAccess`,

        { headers }
      );
      const getAllMiningcard = response?.data;

      setMiningCardShow(getAllMiningcard);
      setMiningCard(true);
      setMiningInactiveCard(true);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const getContractList = async (contractID: any) => {
    const headers = {
      LoginToken: "9508a91f-d366-49d9-b26c-daa6d9a248ea",
      "Content-Type": "text/plain",
    };
    try {
      const response = await Api.post(
        `/api/Plods/ID_${plod_id}/MiningCompany/ID_${contractID}/Contract`,

        { headers }
      );
      const getAllContract = response?.data;

      setContractList(getAllContract);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const getTeamsList = async () => {
    const graphqlQuery = `
          {
            TeamMembers() {
              firstname
              lastname
              owner
              ID
              Roles() {
                ID
                RoleName
              }
            }
          }
        `;

    const headers = {
      LoginToken: "0538dcc3-0826-475d-a06b-dd3d2ab0401b",
      "Content-Type": "text/plain",
    };

    try {
      const response = await Api.post(
        `/api/Plods/ID_${plod_id}/TeamMembers/~GraphQL`,
        graphqlQuery,
        { headers }
      );
      const getAllData = response.data.data.TeamMembers;
      const newRows = getAllData.map((i: any) => {
        return {
          ...i,
          RoleId: i.Roles?.[0]?.ID || "",
        };
      });
      setDataRows(newRows);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const addLinkToRole = async (selectedRoleId: any, rowIdSend: any) => {
    const data = {
      Link_Value: { ID: selectedRoleId },
    };
    try {
      const response = await Api.post(
        `/api/Plods/ID_${plod_id}/TeamMembers/ID_${rowIdSend}/Roles/~AddLinkTo`,
        data
      );
      if (response.status == 200) {
        getTeamsList();
      }
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  // Call the function

  const getRoles = async () => {
    await Api.post(`/api/Plods/ID_${plod_id}/Role`)
      .then(({ data }) => {
        try {
          setRoles(data);
        } catch {
          setRoles([]);
        }
      })
      .catch((e) => {
        handleErrorResponse(e);
      });
  };

  const getMenuItems = async () => {
    try {
      const response = await Api.post(
        `/api/Plods/ID_${plod_id}/Role/ID_${selectedRole}/MenuItem`
      );
      setMenuItems(response.data);
    } catch (error) {
      console.error("Error fetching menu items:", error);
      setMenuItems([]); // Set an empty array or handle error state accordingly
    }
  };

  useEffect(() => {
    if (selectedRole) {
      getMenuItems();
    }
  }, [selectedRole]);

  useEffect(() => {
    getTeamsList();
    getRoles();
  }, []);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params: any,
    event: any
  ) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    try {
      if (!newRow.RoleName) {
        Swal.fire({
          icon: "warning",
          title: "Role name is required.",
        });
        return;
      }
      if (newRow.isNew) {
        const raw = JSON.stringify({
          ID: newRow.ID,
          RoleName: newRow.RoleName,
        });

        const { data } = await Api.post(
          `/api/Plods/ID_${plod_id}/Role/~AddItem`,
          raw
        );

        if (data.ID) {
          const updatedRow = { ...newRow, isNew: false, ID: data.ID };
          setRoles(
            roles.map((row: any) =>
              row.ID === newRow.ID ? { ...updatedRow } : row
            )
          );
          return updatedRow;
        } else {
          swal({
            title: "Error",
            text: `Failed adding data!`,
            icon: "error",
            dangerMode: true,
          });
          return {};
        }
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const processTeamRowUpdate = async (newRow: GridRowModel) => {
    try {
      await addLinkToRole(newRow.RoleId, newRow.ID);
      const updatedRow = { ...newRow, isNew: false, ID: newRow.ID };
      setDataRows(
        dataRows.map((row: any) =>
          row.ID === newRow.ID ? { ...updatedRow } : row
        )
      );
      return updatedRow;
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: "Do you want to Delete this row?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result: any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const raw = JSON.stringify({ ID: id });

        Api.post(`/api/Plods/ID_${plod_id}/Role/~DeleteItem`, raw)
          .then(({ data }: any) => {
            if (data.Result == "Deleted") {
              setRoles(roles.filter((row: any) => row.ID !== id));
            } else {
              Swal.fire(
                "Error deleting, make sure is not in use!",
                "",
                "error"
              );
            }
          })
          .catch((e) => {
            handleErrorResponse(e);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    if (selectedTab == "roles") {
      setDataRowModesModel({
        ...roles,
        [id]: { mode: GridRowModes.View },
      });
    } else {
      setDataRowModesModel({
        ...dataRows,
        [id]: { mode: GridRowModes.View },
      });
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    if (selectedTab == "roles") {
      setDataRowModesModel({
        ...roles,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const editedRow: any = roles.find((row: any) => row.ID === id);
      if (editedRow!.isNew) {
        setRoles(roles.filter((row: any) => row.ID !== id));
      }
    } else {
      setDataRowModesModel({
        ...dataRows,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const editedRow: any = dataRows.find((row: any) => row.ID === id);
      if (editedRow!.isNew) {
        setDataRows(dataRows.filter((row: any) => row.ID !== id));
      }
    }
  };
  interface EditToolbarProps {
    setData: (setData: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setDataRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  const handleEditClick = (id: GridRowId) => () => {
    setDataRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  let TeamsColunms: GridColDef<any>[] = [
    {
      headerName: "First Name",
      field: "firstname",
      flex: 1,
      editable: false,
      type: "string",
    },
    {
      headerName: "Last Name",
      field: "lastname",
      flex: 1,
      editable: false,
      type: "string",
    },
    {
      headerName: "Role",
      field: "RoleId",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: roles.map((role: any) => ({
        value: role.ID,
        label: role.RoleName,
      })),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  let RolesColunms: GridColDef<any>[] = [
    {
      headerName: "ID",
      field: "ID",
      flex: 1,
      editable: false,
      type: "string",
    },
    {
      headerName: "Role",
      field: "RoleName",
      flex: 1,
      editable: true,
      type: "string",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  let MenuColumns: GridColDef<any>[] = [
    {
      headerName: "ID",
      field: "ID",
      align: "left",
      headerAlign: "left",
      flex: 0.08,
      editable: false,
      type: "number",
    },
    {
      headerName: "Access",
      field: "Active",
      flex: 0.1,
      editable: false,
      type: "string",
      renderCell: (params) => {
        const [checked, setChecked] = React.useState(params.row.Active);
        const handleCheckboxChange = async (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          const newChecked = event.target.checked;
          setChecked(newChecked);

          try {
            // Make an API call to update the item status
            const response = await Api.post(
              `/api/Plods/ID_${plod_id}/Role/ID_${selectedRole}/MenuItem/~UpdateItem`,
              {
                Menu: params.row.Menu,
                ID: params.row.ID,
                Active: newChecked,
              }
            );
          } catch (error) {
            console.error("Error updating item status:", error);
            // Handle error
          }
        };
        useEffect(() => {
          setChecked(params.row.Active);
        }, [params.row.Active]);

        return (
          <Checkbox
            checked={checked}
            value={params.row.Active}
            onChange={handleCheckboxChange}
            inputProps={{ "aria-label": "select checkbox" }}
          />
        );
      },
    },
    {
      headerName: "Menu",
      field: "Menu",
      flex: 0.8,
      editable: false,
      type: "string",
    },
  ];

  interface EditToolbarProps {
    setDataRows: (newDataRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setDataRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function CustomToolbar(props: EditToolbarProps) {
    const { setDataRows, setDataRowModesModel } = props;
    const handleAddRoleClick = () => {
      const ID = randomId();
      setDataRows((oldRows: any) => [...oldRows, { ID, isNew: true }]);
      setDataRowModesModel((oldModel: any) => ({
        ...oldModel,
        [ID]: { mode: GridRowModes.Edit, fieldToFocus: "RoleName" },
      }));
    };

    return (
      <>
        <GridToolbarContainer
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box sx={{ borderBottom: 1, marginTop: 2, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={(e: any, value) => {
                setSelectedTab(value);
                setSeletedRole(null);
              }}
            >
              <Tab value="roles" label="Roles" />
              <Tab value="teams" label="Team" />
            </Tabs>
          </Box>

          {selectedTab === "roles" && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddRoleClick}
            >
              Add Role
            </Button>
          )}
        </GridToolbarContainer>
        <hr />
      </>
    );
  }

  const UserRoleBox = ({
    oneUserData,
    dropDownRole,
    loading,
    roleMenu,
  }: any) => {
    return (
      <Box
        sx={{
          border: 1,
          borderColor: "#DEDEDE",
          borderRadius: 2,
          padding: 2,
          marginTop: 2,
          width: "50%",
          marginLeft: 1.5,
        }}
      >
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Typography variant="body1">
            <strong>{oneUserData?.firstname}</strong> has the role of{" "}
            <strong>{oneUserData?.Roles?.[0]?.RoleName}</strong> {dropDownRole}{" "}
            <br />
            The <strong>{oneUserData?.Roles?.[0]?.RoleName}</strong> role grants
            the following menu access:
            <br />
            <br />
            {roleMenu
              ?.filter((item: any) => item?.Active)
              .map((item: any, index: number) => (
                <span key={index}>
                  {item?.Menu}
                  {","}
                </span>
              ))}
          </Typography>
        )}
      </Box>
    );
  };

  const DataPrivilegesBox: React.FC<any> = ({
    dataPrivileges,
    handleRadioChange,
    showAllMiningCompaniesCard,
    showNoMiningCompaniesCard,
    handleCloseCard,
    oneUserData,
    selectedMiningCompanies,
    handleSelectChange,
    miningList,
    isLoading,
    getAddMiningList,
  }) => {
    return (
      <Box
        sx={{
          borderRadius: 2,
          border: "none",
          padding: 2,
          marginTop: 2,
          width: "50%",
        }}
      >
        <FormControl>
          <FormLabel>Data Privileges</FormLabel>
          <RadioGroup
            aria-label="Data Privileges"
            name="dataPrivileges"
            value={dataPrivileges}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="ALL"
              control={<Radio />}
              label="All Mining Companies"
            />
            <FormControlLabel
              value="NONE"
              control={<Radio />}
              label="No Mining Companies"
            />
            <FormControlLabel
              value="selectedMiningCompanies"
              control={<Radio />}
              label="Selected Mining Companies"
            />
          </RadioGroup>
        </FormControl>

        <Card
          variant="outlined"
          sx={{
            display: showAllMiningCompaniesCard ? "block" : "none",
            mt: 2,
            width: "40%",
          }}
        >
          <CardContent sx={{ position: "relative" }}>
            <Typography variant="body1">
              <strong>Gold card</strong>
              <br />
              <br />
              All Mining Companies, All Contracts, All Data
              <br />
              <br />
              <strong>{oneUserData?.firstname}</strong> has access to all data
              in all Mining Companies and all contracts, Drilling Programs, and
              Rigs.
            </Typography>
            <CloseIcon
              onClick={handleCloseCard}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                cursor: "pointer",
              }}
            />
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          sx={{
            display: showNoMiningCompaniesCard ? "block" : "none",
            mt: 2,
            width: "40%",
          }}
        >
          <CardContent sx={{ position: "relative" }}>
            <Typography variant="body1">
              <strong>Access Revoked</strong>
              <br />
              <br />
              No Mining Company Access
              <br />
              <br />
              <strong>{oneUserData?.firstname}</strong> cannot access
              <br /> any mining company information.
              <br />
              <br />
              This team member may have access to add or delete other data
              pending on the menu items they have access to.
            </Typography>
            <CloseIcon
              onClick={handleCloseCard}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                cursor: "pointer",
              }}
            />
          </CardContent>
        </Card>

        {dataPrivileges === "selectedMiningCompanies" && (
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="select-mining-company-label">
                Select Mining Company
              </InputLabel>
              <Select
                value={selectedMiningCompanies}
                labelId="select-mining-company-label"
                id="select-mining-company"
                autoWidth
                label="Choose one…"
                onChange={handleSelectChange}
              >
                <MenuItem disabled value="">
                  <em>None</em>
                </MenuItem>
                {miningList?.map((item: any) => (
                  <MenuItem
                    key={item.ID_EA_MiningCompany}
                    value={item.ID_EA_MiningCompany}
                  >
                    {item.MiningCompanyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <InputLabel sx={{ fontSize: 35 }} onClick={getAddMiningList}>
              {isLoading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              ) : (
                "+"
              )}
            </InputLabel>
          </Box>
        )}
      </Box>
    );
  };

  const MiningCard: React.FC<any> = ({
    selectedTab,
    selectedRowDataTeams,
    dataPrivileges,
    miningCard,
    miningCardShow,
    selectedMiningContractCompanies,
    handleSelectContractChange,
    contractList,
    oneUserData,
    handleMiningCloseCard,
    fetchContracts,
    isLoading,
  }) => {

    const [loadingIndex, setLoadingIndex] = useState<number | null>(null);

    const handleMouseDown = async (index: number, miningCompanyName: string) => {
      setLoadingIndex(index);
      await fetchContracts(miningCompanyName);
    };

    
    return (
      <Paper sx={{ display: "flex" }}>
        {selectedTab === "teams" &&
          selectedRowDataTeams &&
          dataPrivileges === "selectedMiningCompanies" &&
          miningCard &&
          miningCardShow
          ? miningCardShow.map(
            (item: any, index: any) =>
              item.AllContracts && (
                <Card
                  variant="outlined"
                  sx={{ mt: 2, ml: 2, mb: 2, width: "20%" }}
                  key={index}
                >
                  <CardContent sx={{ position: "relative" }}>
                    <Typography variant="body1">
                      <strong>{item.MiningCompanyName}</strong>
                      <br />
                      <Box
                        sx={{ mt: 2, display: "flex", alignItems: "center" }}
                      >
                        <FormControl sx={{ m: 1, minWidth: 270 }}>
                          <InputLabel id={`select-contract-label-${index}`}>
                            All Contracts DP and Rings
                          </InputLabel>
                          <Select
                            value={selectedMiningContractCompanies}
                            labelId={`select-contract-label-${index}`}
                            id={`select-contract-${index}`}
                            autoWidth
                            label="Choose one…"
                            onChange={(event) => {
                              handleSelectContractChange(event);
                            }}
                            onClick={() => handleMouseDown(index, item.ID)}
                          >
                            <MenuItem
                              value=""
                              sx={{ minWidth: 270 }}
                              disabled
                            >
                              All Contracts DP and Rings
                            </MenuItem>
                            {isLoading ? (
                              <MenuItem value="">
                                <Box sx={{ display: "flex" }}>
                                  <CircularProgress size={24} />
                                </Box>
                              </MenuItem>
                            ) : (
                              contractList?.map(
                                (contract: any, contractIndex: any) => (
                                  <MenuItem
                                    key={contractIndex}
                                    value={contract.ContractName}
                                  >
                                    {contract.ContractName}
                                  </MenuItem>
                                )
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                      <br />
                      <strong>{oneUserData?.firstname}</strong> can access to
                      all contracts for the mining companies{" "}
                      <strong>{item.MiningCompanyName}</strong>
                    </Typography>
                    <CloseIcon
                      onClick={handleMiningCloseCard}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </CardContent>
                </Card>
              )
          )
          : null}
      </Paper>
    );
  };
  //#endregion

  return (
    <>
      {/* The Data Grid */}
      <Box
        sx={{
          height: "60vh",
          margin: "10px",
          border: 1,
          borderColor: "#DEDEDE",
          borderRadius: 2,
        }}
      >
        {selectedTab == "teams" ? (
          <DataGrid
            rows={dataRows}
            columns={TeamsColunms}
            getRowId={(row) => row.id || row.ID || row}
            editMode="row"
            rowModesModel={dataRowModesModel}
            onRowSelectionModelChange={async (row: any) => {
              const teamMemberId = row?.[0];
              const teamMember = dataRows?.find((r: any) => r.ID == row?.[0]);
              setOneUserData(teamMember);
              setSelectedRowDataTeams([teamMember]);
              setMiningInactiveCard(false);
              await Promise.all([
                getMenuList(teamMemberId),
                getCompanyAccess(teamMemberId),
                getMiningList(),
              ]);
            }}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processTeamRowUpdate}
            slots={{
              toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                setRows: setDataRows,
                setRowModesModel: setDataRowModesModel,
              },
            }}
          />
        ) : (
          <DataGrid
            sx={{ zIndex: 0 }}
            columns={RolesColunms}
            rows={roles}
            onRowSelectionModelChange={(row: any) => {
              setSeletedRole(row?.[0]);
            }}
            rowModesModel={dataRowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            isCellEditable={(params) => params.row.isNew} //This makes it not editable unless its new.
            getRowId={(row) => row.id || row.ID || row}
            editMode="cell"
            slots={{
              toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                setDataRows: selectedTab == "teams" ? setDataRows : setRoles,
                setDataRowModesModel,
              },
            }}
            columnVisibilityModel={{
              ID: false,
            }}
          />
        )}
      </Box>
      {selectedTab === "teams" && selectedRowDataTeams && (
        <>
          <UserRoleBox
            oneUserData={oneUSerData}
            dropDownRole={dropDownRole}
            loading={loading}
            roleMenu={roleMenu}
          />

          <DataPrivilegesBox
            dataPrivileges={dataPrivileges}
            handleRadioChange={handleRadioChange}
            showAllMiningCompaniesCard={showAllMiningCompaniesCard}
            showNoMiningCompaniesCard={showNoMiningCompaniesCard}
            handleCloseCard={handleCloseCard}
            oneUserData={oneUSerData}
            selectedMiningCompanies={selectedMiningCompanies}
            handleSelectChange={handleSelectChange}
            miningList={miningList}
            isLoading={isloading}
            getAddMiningList={getAddMiningList}
          />
        </>
      )}

      {/* Menu Item Tables */}
      {selectedRole && (
        <Box
          sx={{
            height: "60vh",
            margin: "10px",
            border: 1,
            borderColor: "#DEDEDE",
            borderRadius: 2,
          }}
        >
          <DataGrid
            sx={{ zIndex: 0 }}
            columns={MenuColumns}
            rows={[...addmenuitems]} // Use the menuItems state for rows
            getRowId={(row) => row.ID}
            columnVisibilityModel={{
              ID: false,
            }}
          />
        </Box>
      )}

      {/*Mining Company Card for Active True */}
      <MiningCard
        selectedTab={selectedTab}
        selectedRowDataTeams={selectedRowDataTeams}
        dataPrivileges={dataPrivileges}
        miningCard={miningCard}
        miningCardShow={miningCardShow}
        selectedMiningContractCompanies={selectedMiningContractCompanies}
        handleSelectContractChange={handleSelectContractChange}
        contractList={contractList}
        oneUserData={oneUSerData}
        handleMiningCloseCard={handleMiningCloseCard}
        fetchContracts={getContractList}
        isLoading={isloading}
      />

      {/*Mining Company Card for Active False */}

      <Paper sx={{ display: "flex" }}>
        {selectedTab === "teams" &&
          selectedRowDataTeams &&
          dataPrivileges === "selectedMiningCompanies" &&
          miningInactiveCard
          ? miningCardShow.map(
            (item: any, index: any) =>
              item?.AllContracts == false && (
                <Card
                  variant="outlined"
                  sx={{
                    // display: showAllMiningCompaniesCard ? "block" : "none",
                    mt: 2,
                    ml: 2,
                    mb: 2,
                    width: "20%",
                  }}
                  key={index}
                >
                  <CardContent sx={{ position: "relative" }}>
                    <Typography variant="body1">
                      <strong>Slumberger</strong>
                      <br />
                      <Box
                        sx={{ mt: 2, display: "flex", alignItems: "center" }}
                      >
                        <FormControl sx={{ m: 1, minWidth: 270 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            Contract ABC
                          </InputLabel>
                          <Select
                            value={selectedMiningContractCompanies}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            autoWidth
                            label="Choose one…"
                            onChange={handleSelectContractChange}
                          >
                            {" "}
                            <MenuItem
                              value="contract1"
                              sx={{ minWidth: 270 }}
                              disabled
                            >
                              Contract ABC
                            </MenuItem>
                          </Select>

                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Contract ABC"
                              />
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ ml: 2 }}
                                  control={<Checkbox />}
                                  label="All DP, All rigs"
                                />
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{ ml: 4 }}
                                    control={<Checkbox />}
                                    label="Drilling Program 1"
                                  />
                                  <FormGroup>
                                    <FormControlLabel
                                      sx={{ ml: 7 }}
                                      control={<Checkbox />}
                                      label="All Rigs"
                                    />
                                    <FormControlLabel
                                      sx={{ ml: 7 }}
                                      control={<Checkbox />}
                                      label="Rig 1"
                                    />
                                    <FormControlLabel
                                      sx={{ ml: 7 }}
                                      control={<Checkbox />}
                                      label="Rig 2"
                                    />
                                    <FormControlLabel
                                      sx={{ ml: 7 }}
                                      control={<Checkbox />}
                                      label="Rig 3"
                                    />
                                  </FormGroup>
                                </FormGroup>
                              </FormGroup>
                            </FormGroup>
                          </FormControl>
                        </FormControl>
                      </Box>
                    </Typography>
                    <CloseIcon
                      onClick={handleMiningInactiveCloseCard}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                      }}
                    />
                  </CardContent>
                </Card>
              )
          )
          : ""}
      </Paper>
    </>
  );
};

export default Teams;

//#region ITS JUST ANNOYING IMPORTS.
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridEditInputCell,
    GridEditSingleSelectCell,
    GridEditSingleSelectCellProps,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp,
    GridToolbarContainer,
    useGridApiContext,
} from "@mui/x-data-grid";
import React from "react";
import Swal from "sweetalert2";
import { MiningCompany } from "../types/miningCompanyType";
import { Contract } from "../types/contractType";
import { Shift } from "../types/shiftType";
import { singleSelectOptions } from "../types/gridOptions";
import { DrillingProgram } from "../types/drillingProgramType";
import {
    Box,
    Button,
    FormHelperText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextareaAutosize,
} from "@mui/material";
import dayjs from "dayjs";

import NewPlodBox from "../components/newPlodBox";
//The images.
import ShiftInformation from "./../assets/ShiftInformation.gif";
import Consumables from "./../assets/Consumables.gif";
import Tasks from "./../assets/Tasks.gif";
import DrillingDetails from "./../assets/DrillingDetails.gif";
import Operator from "./../assets/Operator.png";
import ShiftComments from "./../assets/ShiftComments.gif";
//The icons.
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import { randomId } from "@mui/x-data-grid-generator";
import isWhiteSpaceOrEmpty from "../functions/isWhiteSpaceOrEmpty";
import checkNewPlodData from "../functions/checkNewPlodData";
import renameKey from "../functions/renameKey";
import PlodDetails from "../components/PlodDetailsComponent";
import Api from "../api/api";
import { handleErrorResponse } from "../api/apiErrorhandle";
import swal from "sweetalert";
//#endregion

//#region TYPE DATE
type OperatorRow = {
    ID: number;
    ID_EA_Personnel: number;
    ID_EA_PersonnelType: number;
    hours: number;
    comment: string;
};

type OperatorOptions = {
    value: number;
    label: string;
    ID_EA_PersonnelType: number;
};

type OperatorTypeOption = {
    value: number;
    label: string;
};

type DrillingDetailsRow = {
    ID: number;
    hole: string;
    from: number;
    to: number;
    total: number;
    totalRecovered: number;
    hours: number;
    bit: string;
    bitSize: number;
};

type BitSizeOptions = {
    value: number;
    label: string;
};

type TaskRow = {
    ID: number;
    task: number;
    hours: number;
    charge: string;
    comment: string;
};

type TaskOption = {
    value: number;
    label: string;
};

type ConsumableRow = {
    ID: number;
    consumable: number;
    quantity: number;
    charge: boolean;
};

type ConsumableOption = {
    value: number;
    label: string;
};

type UpdatePlodData = {
    Operators: OperatorRow[];
    DrillingDetails: DrillingDetailsRow[];
    Tasks: TaskRow[];
    Consumables: ConsumableRow[];
    ShiftComments: string;
};
//#endregion

const ReviewAndSignoff: React.FunctionComponent = () => {
    document.title =
        "Review And Signoff" + " - " + sessionStorage.getItem("PlodName");
    var plod_id = sessionStorage.getItem("plod_id");
    if (plod_id == "" || plod_id == null) {
        window.location.replace(
            "https://login.redochre.cloud/"
        );
    }

    //#region State Variables
    const [shiftRows, setShiftRows] = React.useState<Shift[]>([]);
    const [miningCompanySelectorID, setMiningCompanySelectorID] =
        React.useState<string>("0");
    const [contractSelectorID, setContractSelectorID] =
        React.useState<string>("0");
    const [drillingProgramSelectorID, setDrillingProgramSelectorID] =
        React.useState<string>("0");
    const [miningCompanyOptions, setMiningCompanyOptions] = React.useState<
        MiningCompany[]
    >([]);
    const [contractOptions, setContractOptions] = React.useState<Contract[]>([]);
    const [drillingProgramOptions, setDrillingProgramOptions] = React.useState<
        DrillingProgram[]
    >([]);
    const [shiftRowModesModel, setShiftRowModesModel] =
        React.useState<GridRowModesModel>({});
    const [rigOptions, setRigOptions] = React.useState<singleSelectOptions[]>([]);
    const [siteOptions, setSiteOptions] = React.useState<singleSelectOptions[]>(
        []
    );
    const [visible, setVisible] = React.useState<boolean>(false);
    const [shiftID, setShiftID] = React.useState<string>("0");
    const token = sessionStorage.getItem("loginToken");
    //#endregion

    //#region Fetching data for Mining Company Selector.
    React.useEffect(() => {
        const fetchMiningCompanyData = async () => {
            Api.post(`/api/Plods/ID_${plod_id}/MiningCompany`).then(({ data }) => {
                if (data.length == "0") {
                    setMiningCompanySelectorID("0");
                } else {
                    setMiningCompanySelectorID(data[0].ID_EA_MiningCompany);
                }
                setMiningCompanyOptions(data);
            });
        };
        fetchMiningCompanyData();
    }, []);

    const handleMiningCompanySelectorChange = (
        event: SelectChangeEvent<string>,
        child: React.ReactNode
    ) => {
        setMiningCompanySelectorID(event.target.value);
    };
    //#endregion

    //#region Fetching data for Contract Selector.
    React.useEffect(() => {
        const fetchContractData = async () => {
            Api.post(
                `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract`
            ).then(({ data }) => {
                if (data.length == 0) {
                    setContractSelectorID("0");
                } else {
                    setContractSelectorID(data[0].ID_EC_Contract);
                }
                setContractOptions(data);
            });
        };
        if (miningCompanySelectorID !== "0") {
            fetchContractData();
        }
    }, [miningCompanySelectorID]);

    const handleContractSelectorChange = (
        event: SelectChangeEvent<string>,
        child: React.ReactNode
    ) => {
        setContractSelectorID(event.target.value);
    };
    //#endregion

    //#region Fetching data for Contract Selector.
    React.useEffect(() => {
        const fetchDrillingProgramData = async () => {
            setShiftRows([]);
            await Api.post(
                `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/`
            )
                .then(({ data }) => {
                    if (data.length == 0) {
                        setDrillingProgramSelectorID("0");
                    } else {
                        setDrillingProgramSelectorID(data[0].ID_EDP_Drilling_Program);
                    }
                    setDrillingProgramOptions(data);
                })
                .catch((e) => console.log(e.message));
        };
        if (miningCompanySelectorID !== "0" && contractSelectorID !== "0") {
            fetchDrillingProgramData();
        }
    }, [contractSelectorID]);

    const handleDrillingProgramSelectorChange = (
        event: SelectChangeEvent<string>,
        child: React.ReactNode
    ) => {
        setDrillingProgramSelectorID(event.target.value);
    };
    //#endregion

    //#region fetches the data for grid.
    React.useEffect(() => {
        if (
            miningCompanySelectorID !== "0" &&
            contractSelectorID !== "0" &&
            drillingProgramSelectorID !== "0"
        ) {
            Api.post(
                `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}/Shift`
            )
                .then(({ data }) => {
                    setShiftRows(data);
                })
                .catch((err) => {
                    console.warn(err?.message);
                });
        }
    }, [drillingProgramSelectorID]);
    //#endregion

    //#region For the selectors data (Rig and site)
    React.useEffect(() => {
        const fetchOptionsData = async () => {
            if (drillingProgramSelectorID != '0') {
                var requestOptions = {
                    method: "POST",
                };
                await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}/~DPRig_GetValues`, requestOptions)
                    .then(({ data }) => {
                        data?.forEach((obj: any) => renameKey(obj, 'ID', 'value'))
                        data?.forEach((obj: any) => renameKey(obj, 'RigName', 'label'))
                        setRigOptions(data);
                    })
                    .catch((err: any) => console.warn(err.message));

                await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}/~DPSite_GetValues`, requestOptions)
                    .then(({ data }) => {
                        data?.forEach((obj: any) => renameKey(obj, 'ID', 'value'))
                        data?.forEach((obj: any) => renameKey(obj, 'SiteName', 'label'))
                        setSiteOptions(data);
                    })
                    .catch((err: any) => console.warn(err.message));
            };
        };

        fetchOptionsData();
    }, [drillingProgramSelectorID]);
    //#endregion

    //#region For shift columns.
    const handleShiftCancelClick = (ID: GridRowId) => () => {
        setShiftRowModesModel({
            ...shiftRowModesModel,
            [ID]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow: any = shiftRows.find((row: Shift) => row.ID === ID);

        if (editedRow!.isNew) {
            setShiftRows(shiftRows.filter((row: Shift) => row.ID !== ID));
        }
    };
    const handleShiftEditClick = (ID: GridRowId) => () => {
        setShiftRowModesModel({
            ...shiftRowModesModel,
            [ID]: { mode: GridRowModes.Edit },
        });
    };

    const handleShiftSaveClick = (ID: GridRowId) => () => {
        setShiftRowModesModel({
            ...shiftRowModesModel,
            [ID]: { mode: GridRowModes.View },
        });
    };
    // const handleShiftDeleteClick = (ID: GridRowId) => () => {
    //   //Delete API request to go here.  
    //   setShiftRows(shiftRows.filter((row: Shift) => row.ID !== ID));
    // };

    const handleShiftDeleteClick = (ID: GridRowId) => () => {
        // Display a confirmation dialog
        Swal.fire({
            title: 'Are you sure you want  to delete this shift ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
        })
            .then((result) => {
                if (result.isConfirmed) {
                    // User clicked "Yes, delete it!" in the confirmation dialog
                    const body = {
                        ID
                    }
                    Api.post(
                        `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}/Shift/~DeleteItem`, body
                    )
                        .then(response => {
                            if (response.status == 200) {

                                setShiftRows(shiftRows.filter((row: Shift) => row.ID !== ID));
                            } else {
                                console.error('Failed to delete row:',);
                            }
                        })
                        .catch(error => {
                            console.error('Error while deleting row:', error);
                            Swal.fire({
                                title: 'Shift not be deleted please try again',
                                icon: 'warning',

                            })

                        });
                } else {

                    Swal.fire('Cancelled', 'Your data is safe :)', 'info');
                }
            });
    };


    const processShiftRowUpdate = async (newRow: GridRowModel) => {
        //Need to have that API stuff yk.
        let updatedRow: any = { ...newRow };

        var raw = JSON.stringify({
            "ID": newRow.ID,
            "DayNight": newRow.DayNight,
            "Date": newRow.Date,
            "ID_EA_Rig": newRow.ID_EA_Rig,
            "ID_EA_Site": newRow.ID_EA_Site,
            "Status": newRow.Status
        });
        console.log(raw)
        await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanySelectorID}/Contract/ID_${contractSelectorID}/DrillingProgram/ID_${drillingProgramSelectorID}/Shift/~UpdateItem`, raw)
            .then(({ data }) => {
                updatedRow = { ...newRow, isNew: false };
                setShiftRows(
                    shiftRows.map((row: any) => (row.ID === newRow.ID ? updatedRow : row))
                );
            }).catch((e) => {
                handleErrorResponse(e)
            })

        return updatedRow;

    };

    const shiftColumns: GridColDef<any>[] = [
        {
            field: "Date",
            headerName: "Date",
            flex: 4,
            editable: true,
            type: "date",
            valueFormatter: (params) => dayjs(params.value).format("DD/MMM/YYYY"),
            valueGetter: ({ value }) => value && dayjs(value).format("MM/DD/YYYY"),
        },
        {
            field: "DayNight",
            headerName: "Day/Night",
            flex: 4,
            editable: true,
            type: "singleSelect",
            valueOptions: ["Day", "Night"],
        },
        {
            field: "ID_EA_Rig",
            headerName: "Rig",
            flex: 4,
            editable: true,
            type: "singleSelect",
            valueOptions: rigOptions,
        },
        {
            field: "ID_EA_Site",
            headerName: "Site",
            flex: 4,
            editable: true,
            type: "singleSelect",
            valueOptions: siteOptions,
        },

        {
            field: "Status",
            headerName: "Status",
            flex: 4,
            editable: true,
            type: "singleSelect",
            valueOptions: [
                { value: "NONE", label: "None" },
                { value: "WAITINGFORSUPERVISOR", label: "Waiting For Supervisor" },
                { value: "WAITINGFORCLIENT", label: "Waiting For Client" },
                { value: "APPROVED", label: "Approved" },
            ],
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = shiftRowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleShiftSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleShiftCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleShiftEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleShiftDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const handleShiftRowModesModelChange = (
        newRowModesModel: GridRowModesModel
    ) => {
        setShiftRowModesModel(newRowModesModel);
    };

    const handleShiftRowClickEvent: GridEventListener<"rowClick"> = (
        params, // GridRowParams
        event, // MuiEvent<React.MouseEvent<HTMLElement>>
        details // GridCallbackDetails
    ) => {
        setVisible(true);
        setShiftID(params.row.ID);
    };

    //#endregion

    return (
        <>
            {/* Data Selectors */}
            <div style={{ margin: 20 }}>
                <FormHelperText>Mining Company</FormHelperText>
                <Select
                    id="MiningCompanySelector"
                    value={miningCompanySelectorID}
                    onChange={handleMiningCompanySelectorChange}
                    style={{ width: 250 }}
                >
                    {miningCompanyOptions.length == 0 ? (
                        <MenuItem value="0">No Mining Companys</MenuItem>
                    ) : (
                        miningCompanyOptions.map((data: any): JSX.Element => {
                            return (
                                <MenuItem
                                    key={data["ID_EA_MiningCompany"]}
                                    value={data["ID_EA_MiningCompany"]}
                                >
                                    {data["MiningCompanyName"]}
                                </MenuItem>
                            );
                        })
                    )}
                </Select>
            </div>
            <div style={{ display: "flex", margin: 20 }}>
                <div>
                    <FormHelperText>Contract</FormHelperText>
                    <Select
                        id="ContractSelector"
                        value={contractSelectorID}
                        onChange={handleContractSelectorChange}
                        style={{ width: 250 }}
                    >
                        {contractOptions.length == 0 ? (
                            <MenuItem value="0">No Contracts</MenuItem>
                        ) : (
                            contractOptions.map((data: any): JSX.Element => {
                                return (
                                    <MenuItem
                                        key={data["ID_EC_Contract"]}
                                        value={data["ID_EC_Contract"]}
                                    >
                                        {data["ContractName"]}
                                    </MenuItem>
                                );
                            })
                        )}
                    </Select>
                </div>

                <div style={{ marginLeft: 20 }}>
                    <FormHelperText>Drilling Program</FormHelperText>
                    <Select
                        id="drillingProgramSelector"
                        value={drillingProgramSelectorID}
                        onChange={handleDrillingProgramSelectorChange}
                        style={{ width: 250 }}
                    >
                        {drillingProgramOptions.length == 0 ? (
                            <MenuItem value="0">No Drilling Programs</MenuItem>
                        ) : (
                            drillingProgramOptions.map((data: any): JSX.Element => {
                                return (
                                    <MenuItem
                                        key={data["ID_EDP_Drilling_Program"]}
                                        value={data["ID_EDP_Drilling_Program"]}
                                    >
                                        {data["DrillingProgramName"]}
                                    </MenuItem>
                                );
                            })
                        )}
                    </Select>
                </div>
            </div>

            <Box
                sx={{
                    height: "70vh",
                    margin: "20px",
                    border: 1,
                    borderColor: "#DEDEDE",
                    borderRadius: 2,
                }}
            >
                <DataGrid
                    columns={shiftColumns}
                    rows={shiftRows}
                    editMode="row"
                    getRowId={(row) => row.ID}
                    rowModesModel={shiftRowModesModel}
                    onRowModesModelChange={handleShiftRowModesModelChange}
                    processRowUpdate={processShiftRowUpdate}
                    onRowClick={handleShiftRowClickEvent}
                />
            </Box>

            <div style={visible ? {} : { display: "none" }}>
                <PlodDetails
                    plod_id={plod_id || ""}
                    save={false}
                    drillingProgramSelectorID={drillingProgramSelectorID}
                    contractSelectorID={contractSelectorID}
                    miningCompanySelectorID={miningCompanySelectorID}
                    statusFilter={""}
                    shiftFilter={""}
                    dateValue={dayjs()}
                    rigFilter={"0"}
                    siteFilter={"0"}
                    shiftID={shiftID}
                />
            </div>
        </>
    );
};

export default ReviewAndSignoff;

import React from 'react';
import "./../styles.css";
import { Button } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, GridRowsProp, GridToolbarContainer, MuiEvent } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import renameKey from '../functions/renameKey';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Api from '../api/api';
import { handleErrorResponse } from '../api/apiErrorhandle';

const myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json')
myHeaders.append('Accept', '*/*')


const Clients: React.FunctionComponent = () => {
    document.title = "Clients" + " - " + sessionStorage.getItem("PlodName")
    var plod_id = sessionStorage.getItem("plod_id")
    if (plod_id == "" || plod_id == null) {
        window.location.replace(process.env.REACT_APP_INVALID_TOKEN_REDIRECT_URL || "https://redochre.com/");
    };

    const [rows, setRows] = React.useState<any>([]);
    React.useEffect(() => {
        const fetchData = async () => {
            await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany`)
                .then(({ data }) => {
                    if (data.length != 0) {
                        data?.forEach((obj: any) => renameKey(obj, 'ID', 'id'));
                        setRows(data);
                    }
                    else {
                        setRows([]);
                    }
                }).catch((e) => {
                    handleErrorResponse(e)

                })
        };
        fetchData();
    }, []);


    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        Swal.fire({
            title: 'Do you want to Delete this row?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result: any) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const raw = JSON.stringify({ "ID": id });

                Api.post(`/api/Plods/ID_${plod_id}/MiningCompany/~DeleteItem`, raw)
                    .then(({ data }: any) => {
                        if (data.Result == 'Deleted') {
                            setRows(rows?.filter((row: any) => row.id !== id));
                        }
                        else {
                            Swal.fire('Error deleting, make sure is not in use!', '', 'error');
                        };
                    }).catch((e) => {
                        handleErrorResponse(e)

                    })
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
            };
        });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow: any = rows.find((row: any) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row: any) => row.id !== id));
        };
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        let updatedRow: any = {};
        var raw = JSON.stringify({
            "ID": newRow.id,
            "MiningCompanyName": newRow.MiningCompanyName,
            "MiningCompanyCode": newRow.MiningCompanyCode
        });

        var requestOptions = {
            method: 'POST',
            body: raw,
            header: myHeaders
        };

        if (newRow!.isNew) {

            await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany/~AddItem`, raw)
                .then(({ data }: any) => {
                    if (data.ID) {
                        updatedRow = { ...newRow, isNew: false, id: data.ID };
                        setRows(rows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
                    }
                    else {
                        swal({
                            title: "Error",
                            text: "Error adding.",
                            icon: "error",
                            dangerMode: true,
                        });
                        updatedRow = {};
                    }
                }).catch((e) => {
                    handleErrorResponse(e)

                })
        }
        else {

            await Api.post(`/api/Plods/ID_${plod_id}/MiningCompany/~UpdateItem`, raw)
                .then(datad => {

                    updatedRow = { ...newRow, isNew: false };
                    setRows(rows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
                }).catch((e) => {
                    handleErrorResponse(e)

                })
        };
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params: any, event: any) => {
        event.defaultMuiPrevented = true;
    };

    interface EditToolbarProps {
        setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
        setRowModesModel: (
            newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
        ) => void;
    }
    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            const id = randomId();
            setRows((oldRows: any) => [...oldRows, { id, MiningCompanyName: '', MiningCompanyCode: '', isNew: true }]);
            setRowModesModel((oldModel: any) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'MiningCompanyName' },
            }));
        };

        return (
            <GridToolbarContainer>
                <Button style={{ marginLeft: "auto", marginRight: 0 }} color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add Client
                </Button>
            </GridToolbarContainer>
        );
    };



    return (
        <>
            <Box sx={{ height: "90vh", margin: "10px", border: 1, borderColor: '#DEDEDE', borderRadius: 2 }}>
                <DataGrid sx={{ zIndex: 0 }}
                    columns={[
                        {
                            field: 'MiningCompanyName',
                            headerName: "Client Name",
                            editable: true,
                            flex: 1
                        },
                        {
                            field: 'MiningCompanyCode',
                            headerName: "Client Code",
                            editable: true,
                            flex: 1
                        },
                        {
                            field: 'actions',
                            type: 'actions',
                            headerName: 'Actions',
                            width: 100,
                            cellClassName: 'actions',
                            getActions: ({ id }) => {
                                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                                if (isInEditMode) {
                                    return [
                                        <GridActionsCellItem
                                            icon={<SaveIcon />}
                                            label="Save"
                                            onClick={handleSaveClick(id)}
                                        />,
                                        <GridActionsCellItem
                                            icon={<CancelIcon />}
                                            label="Cancel"
                                            className="textPrimary"
                                            onClick={handleCancelClick(id)}
                                            color="inherit"
                                        />,
                                    ];
                                }

                                return [
                                    <GridActionsCellItem
                                        icon={<EditIcon />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={handleEditClick(id)}
                                        color="inherit"
                                    />,
                                    <GridActionsCellItem
                                        icon={<DeleteIcon />}
                                        label="Delete"
                                        onClick={handleDeleteClick(id)}
                                        color="inherit"
                                    />,
                                ];
                            }
                        }

                    ]}
                    rows={rows}
                    editMode='row'
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    slotProps={{
                        toolbar: { setRows, setRowModesModel },
                    }}
                />
            </Box>

        </>
    )
};

export default Clients;

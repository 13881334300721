import React from "react";
import { Box, Button, Tab, Tabs } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { randomId } from "@mui/x-data-grid-generator";
import renameKey from "../functions/renameKey";
import renameTwoKey from "../functions/renameTwoKeys";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Api from "../api/api";
import { handleErrorResponse } from "../api/apiErrorhandle";

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Accept", "*/*");
const Bits: React.FunctionComponent = () => {
  document.title = "Bits" + " - " + sessionStorage.getItem("PlodName");
  var plod_id = sessionStorage.getItem("plod_id");
  if (plod_id == "" || plod_id == null) {
    window.location.replace(
      "https://login.redochre.cloud/"
    );
  }

  const [tabValue, setTabValue] = React.useState("BitSizes");

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTabValue(newTab);
    if (newTab != "Manufacturer") {
      setVisible(false);
    }
    fetchData(newTab);
  };
  const [data, setData] = React.useState<any>([]);
  const fetchData = async (Tab: string) => {
    Api.post(`/api/Plods/ID_${plod_id}/${Tab}`)
      .then(({ data }) => {
        data?.forEach((obj: any) => renameKey(obj, "ID", "id"));
        setData(data);
      })
      .catch((e) => {
        handleErrorResponse(e);
      });
  };
  React.useEffect(() => {
    fetchData(tabValue);
  }, [tabValue]);

  //#region Shared Table Data
  const [dataRowModesModel, setDataRowModesModel] =
    React.useState<GridRowModesModel>({});

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: "Do you want to Delete this row?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result: any) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const raw = JSON.stringify({ ID: id });

        Api.post(`/api/Plods/ID_${plod_id}/${tabValue}/~DeleteItem`, raw)

          .then(({ data: res }: any) => {
            if (res.Result == "Deleted") {
              setData((prev: any) => {
                return prev?.filter((row: any) => row.id !== id);
              });
            } else {
              Swal.fire(
                "Error deleting, make sure is not in use!",
                "",
                "error"
              );
            }
          })
          .catch((e) => {
            handleErrorResponse(e);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };
  const handleSaveClick = (id: GridRowId) => () => {
    setDataRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setDataRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow: any = data.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      setData(data?.filter((row: any) => row.id !== id));
    }
  };
  const handleEditClick = (id: GridRowId) => () => {
    setDataRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setDataRowModesModel(newRowModesModel);
  };
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params: any,
    event: any
  ) => {
    event.defaultMuiPrevented = true;
  };
  interface EditToolbarProps {
    setData: (setData: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setDataRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }
  //#endregion
  //#region Row updates
  const processRowUpdate = async (newRow: GridRowModel) => {
    let updatedRow: any = {};
    var raw = "";

    // Check the tabValue to determine the operation
    if (tabValue == "BitSizes") {
      // Check if the Size field is empty
      if (newRow.Size == "" || newRow.Size == " ") {
        swal({
          title: "Error",
          text: "Size cannot be empty",
          icon: "error",
          dangerMode: true,
        });
        return; // Exit early if there's an error
      }
      raw = JSON.stringify({ ID: newRow.id, Size: newRow.Size });
    } else if (tabValue == "BitType") {
      // Check if the BitType field is empty
      if (newRow.BitType == "" || newRow.BitType == " ") {
        swal({
          title: "Error",
          text: "Bit Type cannot be empty",
          icon: "error",
          dangerMode: true,
        });
        return; // Exit early if there's an error
      }
      raw = JSON.stringify({ ID: newRow.id, BitType: newRow.BitType });
    } else {
      // Check if the ManufacturerName field is empty
      if (newRow.ManufacturerName == "" || newRow.ManufacturerName == " ") {
        swal({
          title: "Error",
          text: "Manufacturer Name cannot be empty",
          icon: "error",
          dangerMode: true,
        });
        return; // Exit early if there's an error
      }
      raw = JSON.stringify({
        ID: newRow.id,
        ManufacturerName: newRow.ManufacturerName,
      });
    }

    try {
      let responseData;
      // Perform the appropriate API call based on the operation (new or update)
      if (newRow!.isNew) {
        // For new rows, perform the add item operation
        ({ data: responseData } = await Api.post(`/api/Plods/ID_${plod_id}/${tabValue}/~AddItem`, raw));
      } else {
        // For existing rows, perform the update item operation
        ({ data: responseData } = await Api.post(`/api/Plods/ID_${plod_id}/${tabValue}/~UpdateItem`, raw));
      }

      // Check if the response contains the updated ID
      if (responseData && responseData.ID) {
        // Update the row with the new ID and exit save mode
        updatedRow = { ...newRow, isNew: false, id: responseData.ID };
        setData((prev: any) => {
          return prev?.map((row: any) =>
            row.id === newRow.id ? updatedRow : row
          );
        });
      } else {
        // Show an error message if the response doesn't contain the ID
        swal({
          title: "Error",
          text: "Failed to save data.",
          icon: "error",
          dangerMode: true,
        });
      }
    } catch (error) {
      // Handle API call errors
      handleErrorResponse(error);
    }

    return updatedRow;
  };


  function EditDataToolbar(props: EditToolbarProps) {
    const { setData, setDataRowModesModel } = props;

    const handleDataClick = () => {
      const id = randomId();
      setData((oldRows: any) => [...oldRows, { id, Name: "", isNew: true }]);
      setDataRowModesModel((oldModel: any) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "Name" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          style={{ marginLeft: "auto", marginRight: 0 }}
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleDataClick}
        >
          Add data
        </Button>
      </GridToolbarContainer>
    );
  }
  //#endregion

  let columns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field:
        tabValue == "BitSizes"
          ? "Size"
          : tabValue == "BitType"
            ? "BitType"
            : "ManufacturerName",
      editable: true,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = dataRowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  //#region For setting visible + which manufacturer was clicked)
  const [visible, setVisible] = React.useState(false);
  const [manufacturerID, setManufacturerID] = React.useState(0);
  const handleRowClickEvent: GridEventListener<"rowClick"> = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    if (tabValue != "Manufacturer") {
      setVisible(false);
    } else {
      setVisible(true);
      setManufacturerID(params.row.id);
    }
  };
  //#endregion

  //#region Second Table (Bit assigning)
  const [bitData, setBitData] = React.useState<any>([]);

  const fetchBitData = async (Tab: string) => {
    if (manufacturerID != 0) {
      await Api.post(
        `/api/Plods/ID_${plod_id}/Manufacturer/ID_${manufacturerID}/Bits`
      )
        .then(({ data }) => {
          if (manufacturerID != 0) {
            data?.forEach((obj: any) => renameKey(obj, "ID", "id"));
            setBitData(data);
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    }
  };

  React.useEffect(() => {
    fetchBitData(tabValue);
  }, [manufacturerID]);

  //#region BIT TYPE STUFF
  const [bitTypeOptions, setBitTypeOptions] = React.useState([]);
  React.useEffect(() => {
    const fetchBitTypeData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/BitType`)
        .then(({ data }: any) => {
          data?.forEach((obj: any) =>
            renameTwoKey(obj, "ID_EA_BitType", "value", "BitType", "label")
          );
          setBitTypeOptions(data);
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    };
    fetchBitTypeData();
  }, [tabValue == "Manufacturer"]);
  //#endregion

  //#region BIT SIZE STUFF
  const [bitSizeOptions, setBitSizeOptions] = React.useState([]);
  React.useEffect(() => {
    const fetchBitSizeData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/BitSizes`)
        .then(({ data }: any) => {
          data?.forEach((obj: any) =>
            renameTwoKey(obj, "ID_EA_BitSize", "value", "Size", "label")
          );
          setBitSizeOptions(data);
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    };
    fetchBitSizeData();
  }, [tabValue == "Manufacturer"]);
  //#endregion

  const [bitRowModesModel, setBitRowModesModel] =
    React.useState<GridRowModesModel>({});
  const handleBitDeleteClick = (id: GridRowId) => () => {
    const raw = JSON.stringify({ ID: id });

    var plod_id = sessionStorage.getItem("plod_id");
    if (manufacturerID != 0) {
      Api.post(
        `/api/Plods/ID_${plod_id}/${tabValue}/ID_${manufacturerID}/Bits/~DeleteItem`,
        raw
      )
        .then(({ data }: any) => {
          if (data.Result == "Deleted") {
            setBitData(bitData?.filter((row: any) => row.id !== id));
          } else {
            swal({
              title: "Error",
              text: "Error deleting, make sure is not in use!",
              icon: "error",
              dangerMode: true,
            });
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    }
  };
  const handleBitRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };
  const handleBitSaveClick = (id: GridRowId) => () => {
    setBitRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };
  const handleBitCancelClick = (id: GridRowId) => () => {
    setBitRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow: any = data.find((row: any) => row.id === id);
    if (editedRow?.isNew) {
      setBitData(bitData?.filter((row: any) => row.id !== id));
    }
  };
  const handleBitEditClick = (id: GridRowId) => () => {
    setBitRowModesModel({
      ...dataRowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };
  const handleBitRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ) => {
    setBitRowModesModel(newRowModesModel);
  };
  const handleBitRowEditStop: GridEventListener<"rowEditStop"> = (
    params: any,
    event: any
  ) => {
    event.defaultMuiPrevented = true;
  };
  interface EditToolbarProps {
    setBitData: (setBitData: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setBitRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  const processBitRowUpdate = (newRow: GridRowModel) => {
    let updatedRow: any = {};

    var raw = JSON.stringify({
      ID: newRow.id,
      ID_EA_Manufacturer: manufacturerID,
      ID_EA_BitType: newRow.ID_EA_BitType,
      ID_EA_BitSize: newRow.ID_EA_BitSize,
      BitName: newRow.BitName,
    });

    if (newRow!.isNew) {
      Api.post(
        `/api/Plods/ID_${plod_id}/${tabValue}/ID_${manufacturerID}/Bits/~AddItem`,
        raw
      )
        .then(({ data }: any) => {
          if (data.ID) {
            updatedRow = { ...newRow, isNew: false, id: data.ID };
            setBitData(
              bitData.map((row: any) =>
                row.id === newRow.id ? updatedRow : row
              )
            );
          } else {
            swal({
              title: "Error",
              text: "Error adding oops!",
              icon: "error",
              dangerMode: true,
            });
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    } else {
      Api.post(
        `/api/Plods/ID_${plod_id}/${tabValue}/ID_${manufacturerID}/Bits/~UpdateItem`,
        raw
      )
        .then((data) => {
          updatedRow = { ...newRow, isNew: false };
          setBitData(
            bitData.map((row: any) => (row.id === newRow.id ? updatedRow : row))
          );
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    }
    return updatedRow;
  };

  function EditBitToolbar(props: EditToolbarProps) {
    const { setBitData, setBitRowModesModel } = props;

    const handleBitDataClick = () => {
      const id = randomId();
      setBitData((oldRows: any) => [...oldRows, { id, Name: "", isNew: true }]);
      setBitRowModesModel((oldModel: any) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "Name" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          style={{ marginLeft: "auto", marginRight: 0 }}
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleBitDataClick}
        >
          Add data
        </Button>
      </GridToolbarContainer>
    );
  }
  //#endregion

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          style={{ marginLeft: 20, marginRight: 20 }}
          variant="fullWidth"
          value={tabValue}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="BitSizes" label="Bit Sizes" />
          <Tab value="BitType" label="Bit Types" />
          <Tab value="Manufacturer" label="Manufacturers" />
        </Tabs>
      </Box>

      <Box
        sx={{
          height: 800,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <DataGrid
          sx={{ m: 3 }}
          columns={columns}
          rows={data}
          editMode="row"
          pagination
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          disableRowSelectionOnClick={tabValue == "Manufacturer" ? false : true}
          onRowClick={handleRowClickEvent}
          slots={{
            toolbar: EditDataToolbar,
          }}
          slotProps={{
            toolbar: { setData, setDataRowModesModel },
          }}
        />
      </Box>

      {/* For the manufacturer assigning */}
      <div style={visible ? {} : { display: "none" }}>
        <Box
          sx={{
            height: 800,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            sx={{ m: 3 }}
            columns={[
              {
                field: "BitName",
                headerName: "Bit Name",
                editable: true,
                flex: 0.3,
              },
              {
                field: "ID_EA_BitSize",
                headerName: "Bit Size",
                editable: true,
                flex: 0.3,
                type: "singleSelect",
                valueOptions: bitSizeOptions,
              },
              {
                field: "ID_EA_BitType",
                headerName: "Bit Type",
                editable: true,
                flex: 0.3,
                type: "singleSelect",
                valueOptions: bitTypeOptions,
              },
              {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                width: 100,
                cellClassName: "actions",
                getActions: ({ id }) => {
                  const isInEditMode =
                    bitRowModesModel[id]?.mode === GridRowModes.Edit;

                  if (isInEditMode) {
                    return [
                      <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        onClick={handleBitSaveClick(id)}
                      />,
                      <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleBitCancelClick(id)}
                        color="inherit"
                      />,
                    ];
                  }

                  return [
                    <GridActionsCellItem
                      icon={<EditIcon />}
                      label="Edit"
                      className="textPrimary"
                      onClick={handleBitEditClick(id)}
                      color="inherit"
                    />,
                    <GridActionsCellItem
                      icon={<DeleteIcon />}
                      label="Delete"
                      onClick={handleBitDeleteClick(id)}
                      color="inherit"
                    />,
                  ];
                },
              },
            ]}
            rows={bitData}
            editMode="row"
            pagination
            rowModesModel={bitRowModesModel}
            onRowModesModelChange={handleBitRowModesModelChange}
            onRowEditStart={handleBitRowEditStart}
            onRowEditStop={handleBitRowEditStop}
            processRowUpdate={processBitRowUpdate}
            disableRowSelectionOnClick
            slots={{
              toolbar: EditBitToolbar,
            }}
            slotProps={{
              toolbar: { setBitData, setBitRowModesModel },
            }}
          />
        </Box>
      </div>
    </>
  );
};

export default Bits;
